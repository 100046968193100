import {
    EnergyMeasurement,
    HeightMeasurement,
    Sex,
    User,
    WeightMeasurement,
} from 'client/models/User';

import { deleet, get, patch, post, put } from './http';

export function getUserInfo() {
    return get<User>('/api/user/info');
}

export type NewInfoBody = {
    name: string | null;
    birthday: string | null;
    sex: Sex | null;
    energyMeasurement: EnergyMeasurement;
    heightMeasurement: HeightMeasurement;
    WeightMeasurement: WeightMeasurement;
};

export type NewDefaultMeasurementViewOptionBody = {
    defaultMeasurementViewOption: number;
};

export function patchUserInfo(
    newUserInfo: NewInfoBody | NewDefaultMeasurementViewOptionBody
) {
    return patch('/api/user/info', newUserInfo);
}

export type ShareLinkSettings = {
    expirationDate: string | null;
    disabled: boolean;
    purpose: string;
};

export function createShareLink(shareLinkSettings: ShareLinkSettings) {
    return post<number>('/api/user/share', shareLinkSettings);
}

export type ShareLinkListing = {
    shareId: number;
    shareLink: string;
    creationDate: string;
    expirationDate: string | null;
    disabled: boolean;
    purpose: string;
    visits: number;
};

export function getShareLinks() {
    return get<ShareLinkListing[]>('/api/user/share');
}

export function deleteShareLink(shareLink: ShareLinkListing) {
    return deleet(`/api/user/share?shareId=${shareLink.shareId}`);
}

export function updateShareLink(shareLink: ShareLinkListing) {
    return patch(`/api/user/share?shareId=${shareLink.shareId}`, shareLink);
}

export function getShareLinkUserInfo(shareLink: string) {
    return get<User>(`/api/user/info?shareLink=${shareLink}`);
}

// TODO: Body actually differs depending on `type`.
//       Consider using`MeasurementViewOption` here?
export type CustomViewBody = {
    name: string;
    type: ViewType;
    month: number;
    year: number;
    start: string;
    end: string;
};

export enum ViewType {
    ByMonth = 0,
    ByYear = 1,
    Range = 2,
    Last30Days = 3,
    All = 4,
}

export function putCustomView(customView: CustomViewBody) {
    return put<number>('/api/user/customViews', customView);
}

export function deleteCustomView(customViewId: number) {
    return deleet(`/api/user/customViews?customViewId=${customViewId}`);
}
