import { Nullable } from 'client/helpers';

//type JsDate = InstanceType<typeof globalThis.Date>;
export type DateByNumbers = { year: number; month: number; day: number };
export function isDateByNumbers(value: any): value is DateByNumbers {
    return (
        value &&
        (value as DateByNumbers).day &&
        (value as DateByNumbers).month &&
        ((value as DateByNumbers).year === 0 || (value as DateByNumbers).year)
    );
}
export type TimeByNumbers = { hour: number; minute: number; second: number };
export function isTimeByNumbers(value: any): value is TimeByNumbers {
    return (
        value &&
        ((value as TimeByNumbers).hour === 0 ||
            (value as TimeByNumbers).hour) &&
        ((value as TimeByNumbers).minute === 0 ||
            (value as TimeByNumbers).minute) &&
        ((value as TimeByNumbers).second === 0 ||
            (value as TimeByNumbers).second)
    );
}
export type DateTimeByNumbers = DateByNumbers & TimeByNumbers;
export function isDateTimeByNumbers(value: any): value is DateTimeByNumbers {
    return isDateByNumbers(value) && isTimeByNumbers(value);
}
export type MonthByNumbers = { year: number; month: number };
export function isMonthByNumbers(value: any): value is MonthByNumbers {
    return (
        value &&
        (value as MonthByNumbers).month &&
        ((value as MonthByNumbers).year === 0 || (value as MonthByNumbers).year)
    );
}
export type YearByNumbers = { year: number };
export function isYearByNumbers(value: any): value is YearByNumbers {
    return (
        value &&
        ((value as YearByNumbers).year === 0 || (value as YearByNumbers).year)
    );
}
export type RangeByNumbers = { start: DateByNumbers; end: DateByNumbers };
export function isRangeByNumbers(value: any): value is RangeByNumbers {
    return isDateByNumbers(value.start) && isDateByNumbers(value.end);
}
export function isNullableRangeByNumbers(
    value: any
): value is Nullable<RangeByNumbers> {
    return (
        (value.start === null || isDateByNumbers(value.start)) &&
        (value.end === null || isDateByNumbers(value.end))
    );
}

export function dateByNumbersFromJsDate(jsDate: Date): DateByNumbers {
    const day = jsDate.getDate();
    const month = jsDate.getMonth() + 1;
    const year = jsDate.getFullYear();
    return {
        year,
        month,
        day,
    };
}

export function jsDateFromDateByNumbers(date: DateByNumbers): Date {
    return new Date(Date.parse(dateByNumbersAsIsoDateString(date)));
}

export function dateByNumbersAsIsoDateString({
    year,
    month,
    day,
}: DateByNumbers): string {
    return `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`;
}
