import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import MenuItem from './MenuItem';
import MenuList from './MenuList';
import SidebarToggler from './SidebarToggler';

import MenuModel from '../../models/Menu';
import { RootState } from '../../redux/store';

// Requires second parameter because of `withRouter` for some reason.
const mapState = (state: RootState, _: any) => {
    return {
        isLoggedIn: state.authentication.isLoggedIn,
    };
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type MenuProps = PropsFromRedux & {
    menu: MenuModel;
};

const Menu = ({ menu, isLoggedIn }: MenuProps) => {
    if (
        !menu.namedMenuItems.home ||
        !menu.namedMenuItems.login ||
        !menu.namedMenuItems.logout
    ) {
        return null;
    }

    const homeMenuItem = menu.namedMenuItems.home ? (
        <NavLink
            to={menu.namedMenuItems.home.location}
            className="item"
            activeClassName="active"
            exact
        >
            <h1 className="ui header" style={{ fontSize: 'large' }}>
                {menu.namedMenuItems.home.title}
            </h1>
        </NavLink>
    ) : null;

    const authenticationMenuItem = isLoggedIn ? (
        <MenuItem {...menu.namedMenuItems.logout} />
    ) : (
        <MenuItem {...menu.namedMenuItems.login} />
    );

    return (
        <div>
            {/* Computer menu */}
            <div
                className="ui top attached inverted menu computer tablet only grid"
                style={{ borderRadius: 0 }}
            >
                {homeMenuItem}
                <MenuList menuItems={menu && menu.menuItems} />
                <div className="right menu" style={{ paddingRight: '0' }}>
                    {authenticationMenuItem}
                </div>
            </div>
            {/* Moble and tablet menu */}
            <div
                className="ui top attached inverted menu mobile only grid"
                style={{ marginTop: '0', borderRadius: 0 }}
            >
                {homeMenuItem}
                <div className="right menu" style={{ paddingRight: '0' }}>
                    {authenticationMenuItem}
                    <SidebarToggler />
                </div>
            </div>
        </div>
    );
};

export default withRouter(connector(Menu));
