import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';

import {
    validateAuthentication,
    refreshAuthentication,
} from '../../redux/authenticationSlice';
import { AppDispatch, RootState } from '../../redux/store';

import { fetchUserInfo, clearUserInfo } from '../../redux/userSlice';

const mapState = (state: RootState, _: any) => {
    return {
        isLoggedIn: state.authentication.isLoggedIn,
        loginExpires: state.authentication.loginExpires,
    };
};

const mapDispatch = (dispatch: AppDispatch) => {
    return {
        validateAuthentication: () => {
            dispatch(validateAuthentication());
        },
        refreshAuthentication: () => {
            dispatch(refreshAuthentication());
        },
        fetchUserInfo: () => {
            dispatch(fetchUserInfo());
        },
        removeUserInfo: () => {
            dispatch(clearUserInfo());
        },
    };
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type AppStateHandlerProps = PropsFromRedux & {};

class AppStateHandler extends Component<AppStateHandlerProps> {
    refreshTimerId: ReturnType<typeof setTimeout> | null = null;

    componentDidMount() {
        const { validateAuthentication } = this.props;

        validateAuthentication();
    }

    componentDidUpdate(prevProps: AppStateHandlerProps) {
        const { isLoggedIn, loginExpires, fetchUserInfo, removeUserInfo } =
            this.props;

        if (prevProps.isLoggedIn !== isLoggedIn) {
            // Logged in has changed
            if (!isLoggedIn) {
                // Got logged out. Remove user info
                removeUserInfo();
            } else {
                // Got logged in. Fetch user info
                fetchUserInfo();
            }
        }

        if (prevProps.loginExpires !== loginExpires) {
            if (this.refreshTimerId !== null) {
                clearTimeout(this.refreshTimerId);
                this.refreshTimerId = null;
            }

            if (loginExpires) {
                // Login expiry has changed. Update timer.
                let expiresIn =
                    new Date(loginExpires).valueOf() - new Date().valueOf();
                expiresIn -= 30000;
                if (expiresIn < 0) {
                    expiresIn = 0;
                }
                this.refreshTimerId = setTimeout(
                    () => this.props.refreshAuthentication(),
                    expiresIn
                );
            }
        }
    }

    render() {
        return null;
    }
}

export default withRouter(connector(AppStateHandler));
