import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Header } from 'semantic-ui-react';

import { setTitle } from '../../services/title';

class Home extends Component {
    componentDidMount() {
        setTitle();
    }

    render() {
        return (
            <Container text>
                <Header as="h2">Welcome to WeightMonitor.org</Header>
                <p>
                    Our vision is to provide a weight monitoring service that is
                    simple, yet powerful, and that helps you keep track of your
                    weight; nothing less, nothing more.
                </p>
                <p>
                    Our service is strictly about input and feedback. We don't
                    offer diets, meal plans, exercise schemes or anything like
                    that. On our site, you get to enter your physical
                    characteristics and enter your weight over a period of time,
                    and in return, you will get to know how your progress is
                    going. Our site calculates how rapidly your weight is
                    falling (or rising) and how large your calorie deficit (or
                    excess) per day is. We'll even calculate how long it will
                    take you to reach your goal if you keep up your current
                    trend.
                </p>
                <p>
                    So, what are you waiting for?{' '}
                    <NavLink to="/register">Sign up</NavLink> or{' '}
                    <NavLink to="/login">sign in</NavLink> and start keeping
                    track of your progress!
                </p>
            </Container>
        );
    }
}

export default Home;
