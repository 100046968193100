import {
    FetchError,
    JsonError,
    isFetchError,
    isJsonError,
} from '../services/http';

export function handlePayloadError(
    payload: string[] | JsonError | FetchError
): string[] {
    if (isJsonError(payload)) {
        return [payload.reason.toString()];
    } else if (isFetchError(payload)) {
        return [payload.toString()];
    } else {
        return payload;
    }
}

type RequiredAndNotNull<T> = {
    [P in keyof T]-?: Exclude<T[P], null | undefined>;
};
