import React from 'react';
import { Grid, Header, List, Segment } from 'semantic-ui-react';

import {
    energy as renderEnergy,
    height as renderHeight,
    weight as renderWeight,
} from 'client/conversions';
import { User } from 'client/models/User';

export type UserInfoProps = Pick<
    User,
    | 'height'
    | 'heightMeasurement'
    | 'idealLowerWeight'
    | 'idealUpperWeight'
    | 'weightMeasurement'
    | 'bmr'
    | 'dee'
    | 'energyMeasurement'
>;

export default function UserInfo({
    height,
    heightMeasurement,
    idealLowerWeight,
    idealUpperWeight,
    weightMeasurement,
    bmr,
    dee,
    energyMeasurement,
}: UserInfoProps) {
    const idealWeight = {
        lower: idealLowerWeight,
        upper: idealUpperWeight,
    };

    return (
        <>
            <Header as="div" attached="top">
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column className="vcentered-parent">
                            <h3 className="vcentered-text">User Info</h3>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Header>
            <Segment attached="bottom">
                <List>
                    {height && (
                        <List.Item>
                            Height: {renderHeight(height, heightMeasurement)}
                        </List.Item>
                    )}
                    {idealWeight && idealWeight.lower && idealWeight.upper && (
                        <List.Item>
                            Ideal weight range:{' '}
                            {renderWeight(
                                idealWeight.lower,
                                weightMeasurement,
                                0
                            )}
                            –
                            {renderWeight(
                                idealWeight.upper,
                                weightMeasurement,
                                0
                            )}
                        </List.Item>
                    )}
                    {bmr && (
                        <List.Item>
                            Basal Metabolic Rate:{' '}
                            {renderEnergy(bmr, energyMeasurement)}
                        </List.Item>
                    )}
                    {dee && (
                        <List.Item>
                            Daily Energy Expenditure:{' '}
                            {renderEnergy(dee, energyMeasurement)}
                        </List.Item>
                    )}
                </List>
            </Segment>
        </>
    );
}
