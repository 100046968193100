import React, { Component } from 'react';
import {
	Container,
	Header
} from 'semantic-ui-react';

import { setTitle } from '../../services/title';

class NotFound extends Component {

	componentDidMount() {
		setTitle("Not Found");
	}

	render() {
		return (
			<Container text>
				<Header as="h2">Not Found</Header>
				<p>We don't know what you're looking for or why you're here.</p>
			</Container>
		);
	}
}

export default NotFound;
