import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Checkbox,
    Confirm,
    Container,
    Form,
    Grid,
    Header,
    Icon,
    Message,
    Placeholder,
    Segment,
    Table,
} from 'semantic-ui-react';

import { formatDate } from '../../helpers';
import { isFetchError, isJsonError } from '../../services/http';
import {
    ShareLinkListing,
    ShareLinkSettings,
    createShareLink,
    deleteShareLink,
    getShareLinks,
    updateShareLink,
} from '../../services/user';
import './Sharing.css';

export function InsertRows({
    insertingErrors,
    onCreateShareLink,
    isLoading,
    isInserting,
}: {
    insertingErrors: never[];
    onCreateShareLink: (newShareLink: ShareLinkSettings) => Promise<void>;
    isLoading: boolean;
    isInserting: boolean;
}) {
    const [insertingShareLink, setInsertingShareLink] = useState({
        expirationDate: '',
        disabled: false,
        purpose: '',
    });

    if (isLoading) return null;

    const insertingErrorElements: JSX.Element[] = [];
    insertingErrors.forEach((insertingError) => {
        insertingErrorElements.push(
            <li key={insertingError}>{insertingError}</li>
        );
    });

    const insertingRows = [];

    if (insertingErrorElements.length) {
        insertingRows.push(
            <Table.Row key="insertErrors" loading={isLoading} data-testid="row">
                <Table.Cell colSpan="6">
                    <Message
                        error
                        header="Error creating share link"
                        content={<ul>{insertingErrorElements}</ul>}
                    />
                </Table.Cell>
            </Table.Row>
        );
    }

    insertingRows.push(
        <Table.Row key="insert">
            <Table.Cell>
                <div className="text grey">Share Code</div>
            </Table.Cell>
            <Table.Cell>
                <Form.Input
                    label="Expires"
                    id="expirationDate"
                    type="date"
                    name="expirationDate"
                    autoComplete="off"
                    value={insertingShareLink.expirationDate}
                    onChange={(_, { value }) => {
                        setInsertingShareLink({
                            ...insertingShareLink,
                            expirationDate: value,
                        });
                    }}
                />
            </Table.Cell>
            <Table.Cell textAlign="center">
                <label>
                    <Checkbox
                        id="disabled"
                        name="disabled"
                        checked={insertingShareLink.disabled}
                        onChange={(_, { checked }) => {
                            setInsertingShareLink({
                                ...insertingShareLink,
                                disabled: checked as boolean,
                            });
                        }}
                        toggle
                    />
                    <br />
                    {insertingShareLink.disabled ? 'Yes' : 'No'}
                </label>
            </Table.Cell>
            <Table.Cell>
                <Form.Input
                    label="Purpose"
                    id="purpose"
                    type="text"
                    name="purpose"
                    autoComplete="off"
                    value={insertingShareLink.purpose}
                    onChange={(_, { value }) => {
                        setInsertingShareLink({
                            ...insertingShareLink,
                            purpose: value,
                        });
                    }}
                />
            </Table.Cell>
            <Table.Cell>&ndash;</Table.Cell>
            <Table.Cell className="actionCell">
                <Icon
                    name="add"
                    data-action="Insert"
                    onClick={() =>
                        onCreateShareLink({ ...insertingShareLink }).then(
                            () => {
                                setInsertingShareLink({
                                    expirationDate: '',
                                    disabled: false,
                                    purpose: '',
                                });
                            }
                        )
                    }
                />
            </Table.Cell>
        </Table.Row>
    );

    return <>{insertingRows}</>;
}

export function PlaceholderRow() {
    return (
        <Table.Row>
            <Table.Cell>
                <Placeholder>
                    <Placeholder.Line />
                </Placeholder>
            </Table.Cell>
            <Table.Cell>
                <Placeholder>
                    <Placeholder.Line />
                </Placeholder>
            </Table.Cell>
            <Table.Cell>
                <Placeholder>
                    <Placeholder.Line />
                </Placeholder>
            </Table.Cell>
            <Table.Cell>
                <Placeholder>
                    <Placeholder.Line />
                </Placeholder>
            </Table.Cell>
            <Table.Cell>
                <Placeholder>
                    <Placeholder.Line />
                </Placeholder>
            </Table.Cell>
            <Table.Cell>
                <Placeholder style={{ height: 24 }}>
                    <Placeholder.Image />
                </Placeholder>
            </Table.Cell>
        </Table.Row>
    );
}

export function ShareLinkRow({
    shareLink,
    onUpdateShareLink,
    onDeleteShareLink,
}: {
    shareLink: ShareLinkListing;
    onUpdateShareLink: (shareLink: ShareLinkListing) => Promise<void>;
    onDeleteShareLink: (shareLink: ShareLinkListing) => Promise<void>;
}) {
    const [editState, setEditState] = useState<ShareLinkListing | null>(null);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const shareLinkUrl = `${window.location.protocol}//${window.location.host}/user/${shareLink.shareLink}`;

    let expirationDate: string;
    if (shareLink.expirationDate) {
        expirationDate = formatDate(shareLink.expirationDate);
    } else {
        expirationDate = 'Never';
    }

    if (!editState) {
        return (
            <Table.Row>
                <Table.Cell>
                    <a href={shareLinkUrl}>{shareLink.shareLink}</a>
                </Table.Cell>
                <Table.Cell>{expirationDate}</Table.Cell>
                <Table.Cell textAlign="center">
                    <label>{shareLink.disabled ? 'Yes' : 'No'}</label>
                </Table.Cell>
                <Table.Cell>{shareLink.purpose}</Table.Cell>
                <Table.Cell>{shareLink.visits}</Table.Cell>
                <Table.Cell className="actionCell">
                    <Icon
                        name="edit"
                        data-action="Edit"
                        onClick={() => setEditState({ ...shareLink })}
                    />
                    <Icon
                        name="trash alternate outline"
                        data-action="Delete"
                        color="red"
                        onClick={() => setConfirmDelete(true)}
                    />
                    <Confirm
                        open={confirmDelete}
                        header={`Deleting share link "${shareLink.shareLink}"`}
                        onCancel={() => setConfirmDelete(false)}
                        onConfirm={() => {
                            setConfirmDelete(false);
                            onDeleteShareLink(shareLink);
                        }}
                    />
                </Table.Cell>
            </Table.Row>
        );
    } else {
        return (
            <Table.Row>
                <Table.Cell>
                    <a href={shareLinkUrl}>{editState.shareLink}</a>
                </Table.Cell>
                <Table.Cell>
                    <Form.Input
                        label="Expires"
                        id={`edit-${editState.shareId}-expirationDate`}
                        type="date"
                        name="expirationDate"
                        autoComplete="off"
                        value={editState.expirationDate ?? ''}
                        onChange={(_, { value }) => {
                            setEditState({
                                ...editState,
                                expirationDate: value,
                            });
                        }}
                    />
                </Table.Cell>
                <Table.Cell textAlign="center">
                    <label>
                        <Checkbox
                            id={`edit-${editState.shareId}-disabled`}
                            name="disabled"
                            checked={editState.disabled}
                            onChange={(_, { checked }) => {
                                setEditState({
                                    ...editState,
                                    disabled: checked as boolean,
                                });
                            }}
                            toggle
                        />
                        <br />
                        {editState.disabled ? 'Yes' : 'No'}
                    </label>
                </Table.Cell>
                <Table.Cell>
                    <Form.Input
                        label="Purpose"
                        id={`edit-${editState.shareId}-purpose`}
                        type="text"
                        name="purpose"
                        autoComplete="off"
                        value={editState.purpose}
                        onChange={(_, { value }) => {
                            setEditState({
                                ...editState,
                                purpose: value,
                            });
                        }}
                    />
                </Table.Cell>
                <Table.Cell>&ndash;</Table.Cell>
                <Table.Cell className="actionCell">
                    <Icon
                        name="save"
                        data-action="Update"
                        onClick={() =>
                            onUpdateShareLink(editState).then(() =>
                                setEditState(null)
                            )
                        }
                    />
                    <Icon
                        name="cancel"
                        data-action="Cancel"
                        onClick={() => setEditState(null)}
                    />
                </Table.Cell>
            </Table.Row>
        );
    }
}

export function Sharing() {
    const [isLoading, setIsLoading] = useState(true);
    const [isInserting, setIsInserting] = useState(false);
    const [shareLinks, setShareLinks] = useState<ShareLinkListing[]>([]);
    //const [insertingErrors, setInsertingErrors] = useState([]);
    const [refreshDataCounter, setRefreshDataCounter] = useState(0);

    function refreshData() {
        setRefreshDataCounter((c) => c + 1);
    }

    useEffect(() => {
        let wasCanceled = false;

        async function fetchData() {
            const shareLinks = await getShareLinks();
            if (!wasCanceled) {
                setIsLoading(false);
                if (isJsonError(shareLinks) || isFetchError(shareLinks)) {
                    console.log(
                        'Error getting share links:',
                        shareLinks.toString()
                    );
                    // TODO: Handle error properly
                    return;
                }

                if (shareLinks.ok && shareLinks.data.success) {
                    setShareLinks(shareLinks.data.data);
                } else {
                    // TODO: Handle errors
                    console.log(
                        'Error getting share links:',
                        shareLinks.status,
                        shareLinks.data.message,
                        shareLinks.data.errors
                    );
                }
            }
        }

        setIsLoading(true);
        fetchData();

        return () => {
            wasCanceled = true;
        };
    }, [refreshDataCounter]);

    async function onCreateShareLink(newShareLink: ShareLinkSettings) {
        if (!newShareLink.expirationDate) {
            newShareLink.expirationDate = null;
        }
        setIsInserting(true);
        // TODO: Handle errors from createShareLink
        await createShareLink(newShareLink);
        setIsInserting(false);
        refreshData();
    }

    async function onDeleteShareLink(shareLink: ShareLinkListing) {
        setIsLoading(true);
        // TODO: Handle errors
        await deleteShareLink(shareLink);
        refreshData();
    }

    async function onUpdateShareLink(shareLink: ShareLinkListing) {
        if (!shareLink.expirationDate) {
            shareLink.expirationDate = null;
        }

        setIsLoading(true);
        // TODO: Handle errors
        await updateShareLink(shareLink);
        refreshData();
    }

    const shareLinkRows = [];

    shareLinkRows.push(
        <InsertRows
            key="insert"
            insertingErrors={[]} // TODO: Deal with errors properly
            onCreateShareLink={onCreateShareLink}
            isLoading={isLoading}
            isInserting={isInserting}
        />
    );

    if (isLoading) {
        shareLinkRows.push(<PlaceholderRow key="loading" />);
    }

    shareLinks.forEach((shareLink) => {
        shareLinkRows.push(
            <ShareLinkRow
                key={shareLink.shareId}
                shareLink={shareLink}
                onUpdateShareLink={onUpdateShareLink}
                onDeleteShareLink={onDeleteShareLink}
            />
        );
    });

    return (
        <Container text>
            <Header as="h2">Sharing</Header>
            <p>
                Here you can generate share links to hand out to people you'd
                like to share your weight monitoring with.
            </p>
            <p>
                A share link can be time limited using an expiration time and
                can also be disabled. An expired or disabled link will no longer
                work, so you get to be in full control over who gets to see your
                progress.
            </p>
            <Segment basic loading={isInserting}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column only="tablet computer">
                            <Table>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            Code
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Expires
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Disabled
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Purpose
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Visits
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Actions
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>{shareLinkRows}</Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container>
    );
}

export default connect()(Sharing);
