import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Header, Button, Form, Message } from 'semantic-ui-react';

import { authenticate } from '../../redux/authenticationSlice';
import { AppDispatch, RootState } from '../../redux/store';

import { setTitle } from '../../services/title';

const mapState = (state: RootState, ownProps: any) => {
    return {
        isAuthenticating: state.authentication.isAuthenticating,
        authenticationErrors: state.authentication.authenticationErrors,
        isLoggedIn: state.authentication.isLoggedIn,
        redirectUrl:
            state.authentication.loginRedirectUrl || ownProps.redirectUrl,
    };
};

const mapDispatch = (dispatch: AppDispatch) => {
    return {
        authenticate: (
            username: string,
            password: string,
            rememberMe: boolean
        ) => {
            dispatch(authenticate({ username, password, rememberMe }));
        },
    };
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type LoginProps = PropsFromRedux & RouteComponentProps & {};

type LoginState = {
    email: string;
    password: string;
    rememberMe: boolean;

    formErrors: never[];

    initialLoading: boolean;
    loading: boolean;
    redirected: boolean;
};

class Login extends Component<LoginProps, LoginState> {
    state = {
        email: '',
        password: '',
        rememberMe: false,

        formErrors: [],

        initialLoading: true,
        loading: true,
        redirected: false,
    };

    handleChange = ({ name, value }: { name: string; value: any }) =>
        this.setState({ [name]: value } as any);

    handleSubmit = () => {
        const { authenticate } = this.props;
        const { email, password, rememberMe } = this.state;

        authenticate(email, password, rememberMe);
    };

    redirect() {
        const { history, redirectUrl } = this.props;
        history.push(redirectUrl);
    }

    componentDidMount() {
        const { isLoggedIn } = this.props;
        const { initialLoading } = this.state;

        setTitle('Login');

        if (initialLoading && isLoggedIn !== null) {
            this.setState({ initialLoading: false, loading: false });
        }
    }

    componentDidUpdate(prevProps: LoginProps, prevState: LoginState) {
        const { isLoggedIn } = this.props;
        const { initialLoading, redirected } = this.state;

        if (initialLoading && isLoggedIn != null) {
            this.setState({ initialLoading: false, loading: false });
        }

        if (isLoggedIn && !redirected) {
            this.redirect();
            this.setState({ redirected: true });
        }
    }

    render() {
        const { email, password, rememberMe } = this.state;

        const { isAuthenticating, authenticationErrors } = this.props;

        const formErrorElements: JSX.Element[] = [];
        const usernameErrors: string[] = [];
        const passwordErrors: string[] = [];
        authenticationErrors.forEach((authenticationError) => {
            if (authenticationError.startsWith('User name')) {
                usernameErrors.push(authenticationError);
            } else if (authenticationError.startsWith('Password')) {
                passwordErrors.push(authenticationError);
            } else {
                formErrorElements.push(
                    <li key={authenticationError}>{authenticationError}</li>
                );
            }
        });

        return (
            <Container text>
                <Header as="h2">Login</Header>
                {authenticationErrors.length > 0 && (
                    <Message
                        error
                        header="Error logging in"
                        content={
                            formErrorElements.length ? (
                                <ul>{formErrorElements}</ul>
                            ) : (
                                <></>
                            )
                        }
                    />
                )}
                <Form loading={isAuthenticating} onSubmit={this.handleSubmit}>
                    <Form.Input
                        label="E-mail"
                        id="username"
                        type="text"
                        name="email"
                        placeholder="user@example.com"
                        autoComplete="username"
                        value={email}
                        onChange={(_, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        error={
                            usernameErrors.length
                                ? usernameErrors.join(', \n')
                                : null
                        }
                    />
                    <Form.Input
                        label="Password"
                        id="password"
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(_, { name, value }) =>
                            this.handleChange({ name, value })
                        }
                        error={
                            passwordErrors.length
                                ? passwordErrors.join(', \n')
                                : null
                        }
                    />
                    <Form.Checkbox
                        label="Remember me"
                        id="rememberMe"
                        name="rememberMe"
                        checked={rememberMe}
                        value="rememberMe"
                        onChange={(_, { name }) =>
                            this.handleChange({
                                name: name as string,
                                value: !rememberMe,
                            })
                        }
                    />
                    <Button type="submit">Submit</Button>
                </Form>
            </Container>
        );
    }
}

export default connect(mapState, mapDispatch)(Login);
