import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    createApi,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Nullable } from 'client/helpers';
import {
    MonthByNumbers,
    RangeByNumbers,
    YearByNumbers,
    dateByNumbersFromJsDate,
    isMonthByNumbers,
    isNullableRangeByNumbers,
    isRangeByNumbers,
    isYearByNumbers,
    jsDateFromDateByNumbers,
} from 'client/models/DateByNumbers';
import { Measurement, PresentMeasurement } from 'client/models/Measurement';
import { Trends } from 'client/models/Trends';
import {
    MeasurementViewOption,
    MeasurementViewType,
    User,
} from 'client/models/User';

import { ApiResult } from '../services/http';

const responseHandlerWithErrors: (response: Response) => Promise<any> = async (
    response
) => {
    const result = (await response.json()) as ApiResult<any>;
    if (result.success) {
        return result.data;
    } else {
        throw result.message;
    }
};

// const responseHandlerWithNullOnError: (response: Response) => Promise<any> = async (
//     response
// ) => {
//     const result = (await response.json()) as ApiResult<any>;
//     if (result.success) {
//         return result.data;
//     } else {
//         return null;
//     }
// };

type Builder = EndpointBuilder<
    BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
    >,
    'Measurements' | 'User',
    'weightMonitorApi'
>;

function measurementEndpoints(builder: Builder) {
    function checkInvalidateUser(
        date: string,
        getState: () => RootState<any, any, 'weightMonitorApi'>,
        dispatch: ThunkDispatch<any, any, AnyAction>
    ) {
        const year = new Date(date).getFullYear();
        const getUserInfo = weightMonitorApi.endpoints.getUserInfo.select({
            shareLink: null,
        });
        const userInfo = getUserInfo(getState());
        if (userInfo.status === QueryStatus.fulfilled) {
            const user = userInfo.data;
            if (!user.measurementYears.includes(year)) {
                dispatch(
                    weightMonitorApi.util.invalidateTags([
                        { type: 'User', id: 'LIST' },
                    ])
                );
            }
        }
    }

    return {
        getTrends: builder.query<
            Trends,
            {
                from: string;
                to: string;
                shareLink: string | null;
            }
        >({
            query: ({ from, to, shareLink }) => {
                if (shareLink) {
                    return {
                        url: `measurement/trends?shareLink=${shareLink}&from=${from}&to=${to}`,
                        responseHandler: responseHandlerWithErrors,
                    };
                } else {
                    return {
                        url: `measurement/trends?from=${from}&to=${to}`,
                        responseHandler: responseHandlerWithErrors,
                    };
                }
            },
        }),
        getMeasurements: builder.query<
            Measurement[],
            {
                type: MeasurementViewType;
                value?:
                    | MonthByNumbers
                    | YearByNumbers
                    | Nullable<RangeByNumbers>;
                shareLink: string | null;
            }
        >({
            query: ({ type, value, shareLink }) => {
                const query: { [k: string]: string } = {};
                if (shareLink) {
                    query.shareLink = shareLink;
                }
                let url: string;
                switch (type) {
                    case MeasurementViewType.ByMonth:
                        if (!isMonthByNumbers(value)) {
                            throw new Error(
                                'Calling ByMonth query with value that is not MonthByNumbers: ' +
                                    value
                            );
                        }
                        url = 'byMonth';
                        query.month = value.month.toString();
                        query.year = value.year.toString();
                        break;

                    case MeasurementViewType.ByYear:
                        if (!isYearByNumbers(value)) {
                            throw new Error(
                                'Calling ByYear query with value that is not YearByNumbers: ' +
                                    value
                            );
                        }
                        url = 'byYear';
                        query.year = value.year.toString();
                        break;

                    case MeasurementViewType.Range:
                        if (!isNullableRangeByNumbers(value)) {
                            throw new Error(
                                'Calling Range query with value that is not RangeByNumbers: ' +
                                    value
                            );
                        }
                        url = 'byRange';
                        if (value.start) {
                            query.start = jsDateFromDateByNumbers(value.start)
                                .toISOString()
                                .substring(0, 10);
                        }
                        if (value.end) {
                            query.end = jsDateFromDateByNumbers(value.end)
                                .toISOString()
                                .substring(0, 10);
                        }
                        break;

                    case MeasurementViewType.Last30Days:
                        url = 'last30';
                        break;

                    case MeasurementViewType.All:
                        url = 'all';
                        break;
                }

                const queryString = Object.keys(query)
                    .map((key) => `${key}=${query[key]}`)
                    .join('&');

                return {
                    url: `measurement/${url}?${queryString}`,
                    responseHandler: responseHandlerWithErrors,
                };
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(
                              ({ date }) =>
                                  ({ type: 'Measurements', id: date } as const)
                          ),
                          { type: 'Measurements', id: 'LIST' },
                      ]
                    : // an error occurred, but we still want to refetch this query when `{ id: 'LIST' }` is invalidated
                      [{ type: 'Measurements', id: 'LIST' }],
        }),
        insertMeasurement: builder.mutation<
            Measurement,
            Pick<PresentMeasurement, 'date' | 'weight'>
        >({
            query: (measurement) => ({
                url: `measurement/enter`,
                method: 'POST',
                body: measurement,
                responseHandler: responseHandlerWithErrors,
            }),
            async onQueryStarted(
                { date },
                { dispatch, queryFulfilled, getState }
            ) {
                try {
                    await queryFulfilled;
                    dispatch(
                        weightMonitorApi.util.invalidateTags([
                            { type: 'Measurements', id: 'LIST' },
                        ])
                    );

                    checkInvalidateUser(date, getState, dispatch);
                } catch {}
            },
        }),
        updateMeasurement: builder.mutation<
            Measurement,
            Pick<PresentMeasurement, 'date' | 'weight'>
        >({
            query: (measurement) => ({
                url: `measurement?date=${measurement.date}`,
                method: 'PATCH',
                body: measurement,
                responseHandler: responseHandlerWithErrors,
            }),
            async onQueryStarted(
                { date },
                { dispatch, queryFulfilled, getState }
            ) {
                try {
                    await queryFulfilled;
                    dispatch(
                        weightMonitorApi.util.invalidateTags([
                            { type: 'Measurements', id: date },
                        ])
                    );

                    checkInvalidateUser(date, getState, dispatch);
                } catch {}
            },
        }),
        deleteMeasurement: builder.mutation<
            null,
            Pick<PresentMeasurement, 'date'>
        >({
            query: (measurement) => ({
                url: `measurement?date=${measurement.date}`,
                method: 'DELETE',
                responseHandler: responseHandlerWithErrors,
            }),
            async onQueryStarted({ date }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        weightMonitorApi.util.invalidateTags([
                            { type: 'Measurements', id: 'LIST' },
                        ])
                    );
                    dispatch(
                        weightMonitorApi.util.invalidateTags([
                            { type: 'User', id: 'LIST' },
                        ])
                    );
                } catch {}
            },
        }),
    };
}

function userEndpoints(builder: Builder) {
    return {
        getUserInfo: builder.query<
            User,
            {
                shareLink: string | null;
            }
        >({
            query: ({ shareLink }) => {
                if (shareLink) {
                    return {
                        url: `user/info?shareLink=${shareLink}`,
                        responseHandler: responseHandlerWithErrors,
                    };
                } else {
                    return {
                        url: `user/info`,
                        responseHandler: responseHandlerWithErrors,
                    };
                }
            },
            providesTags: (result) =>
                result
                    ? [
                          { type: 'User', id: result.id },
                          { type: 'User', id: 'LIST' },
                      ]
                    : // an error occurred, but we still want to refetch this query when `{ id: 'LIST' }` is invalidated
                      [{ type: 'User', id: 'LIST' }],
        }),
        insertCustomView: builder.mutation<
            number,
            Omit<MeasurementViewOption, 'id'>
        >({
            query: (customView) => ({
                url: `user/customViews`,
                method: 'POST',
                body: customView,
                responseHandler: responseHandlerWithErrors,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        weightMonitorApi.util.invalidateTags([
                            { type: 'User', id: 'LIST' },
                        ])
                    );
                } catch {}
            },
        }),
        updateCustomView: builder.mutation<null, MeasurementViewOption>({
            query: (customView) => ({
                url: `user/customViews?customViewId=${customView.id}`,
                method: 'PUT',
                body: customView,
                responseHandler: responseHandlerWithErrors,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        weightMonitorApi.util.invalidateTags([
                            { type: 'User', id: 'LIST' },
                        ])
                    );
                } catch {}
            },
        }),
        deleteCustomView: builder.mutation<null, number>({
            query: (customViewId) => ({
                url: `user/customViews?customViewId=${customViewId}`,
                method: 'DELETE',
                responseHandler: responseHandlerWithErrors,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        weightMonitorApi.util.invalidateTags([
                            { type: 'User', id: 'LIST' },
                        ])
                    );
                } catch {}
            },
        }),
        setDefaultCustomView: builder.mutation<null, number>({
            query: (viewOptionId) => ({
                url: `user/info`,
                method: 'PATCH',
                body: {
                    defaultMeasurementViewOption: viewOptionId,
                },
                responseHandler: responseHandlerWithErrors,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        weightMonitorApi.util.invalidateTags([
                            { type: 'User', id: 'LIST' },
                        ])
                    );
                } catch {}
            },
        }),
    };
}

export const weightMonitorApi = createApi({
    reducerPath: 'weightMonitorApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/',
    }),
    tagTypes: ['Measurements', 'User'],
    endpoints: (builder) => ({
        ...measurementEndpoints(builder),
        ...userEndpoints(builder),
    }),
});

export const {
    useGetTrendsQuery,
    useGetMeasurementsQuery,
    useInsertMeasurementMutation,
    useUpdateMeasurementMutation,
    useDeleteMeasurementMutation,

    useGetUserInfoQuery,
    useInsertCustomViewMutation,
    useUpdateCustomViewMutation,
    useDeleteCustomViewMutation,
    useSetDefaultCustomViewMutation,
} = weightMonitorApi;
