import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Header, Form, Button, Message } from 'semantic-ui-react';

import { patchUserInfo } from '../../services/user';
import { fetchUserInfo } from '../../redux/userSlice';

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.authentication.isLoggedIn,
        userInfo: state.user.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserInfo: (e) => {
            dispatch(fetchUserInfo());
        },
    };
};

class Settings extends Component {
    state = {
        newUserInfo: {
            name: '',
            birthday: '',
            sex: '',
            energyMeasurement: '',
            heightMeasurement: '',
            weightMeasurement: '',
        },
        changingUserInfo: false,
        changedUserInfo: false,
        userInfoUpdateErrors: [],
    };

    constructor(props) {
        super(props);

        if (props.userInfo) {
            this.state.newUserInfo.name = props.userInfo.name || '';
            this.state.newUserInfo.birthday = props.userInfo.birthday || '';
            this.state.newUserInfo.sex = props.userInfo.sex || '';
            this.state.newUserInfo.energyMeasurement =
                props.userInfo.energyMeasurement || '';
            this.state.newUserInfo.heightMeasurement =
                props.userInfo.heightMeasurement || '';
            this.state.newUserInfo.weightMeasurement =
                props.userInfo.weightMeasurement || '';
        }
    }

    updateUserInfoField = (e, { name, value }) => {
        const { newUserInfo } = this.state;
        newUserInfo[name] = value;
        this.setState({ newUserInfo });
    };

    handleUpdateUserInfoSubmit = () => {
        const { newUserInfo } = this.state;

        const { fetchUserInfo } = this.props;

        const newUserInfoFixed = { ...newUserInfo };
        if (newUserInfoFixed.name === '') {
            newUserInfoFixed.name = null;
        }
        if (newUserInfoFixed.birthday === '') {
            newUserInfoFixed.birthday = null;
        }
        if (newUserInfoFixed.sex === '') {
            newUserInfoFixed.sex = null;
        }

        this.setState({
            changingUserInfo: true,
            changedUserInfo: false,
            userInfoUpdateErrors: [],
        });

        patchUserInfo(newUserInfoFixed)
            .catch((reason) => {
                this.setState({
                    changingUserInfo: false,
                    changedUserInfo: false,
                    userInfoUpdateErrors: [reason],
                });
            })
            .then((result) => {
                if (result.ok && result.data.success) {
                    this.setState({
                        changingUserInfo: false,
                        changedUserInfo: true,
                        userInfoUpdateErrors: [],
                    });
                    fetchUserInfo();
                } else {
                    this.setState({
                        changingUserInfo: false,
                        changedUserInfo: false,
                        userInfoUpdateErrors:
                            [result.reason] || result.data.errors,
                    });
                }
            });
    };

    componentDidUpdate(prevProps, prevState) {
        const { userInfo } = this.props;

        if (prevProps.userInfo !== userInfo) {
            const props = this.props;
            if (props.userInfo) {
                this.setState({
                    newUserInfo: {
                        name: props.userInfo.name || '',
                        birthday: props.userInfo.birthday || '',
                        sex: props.userInfo.sex || '',
                        energyMeasurement:
                            props.userInfo.energyMeasurement || '',
                        heightMeasurement:
                            props.userInfo.heightMeasurement || '',
                        weightMeasurement:
                            props.userInfo.weightMeasurement || '',
                    },
                });
            }
        }
    }

    render() {
        const {
            newUserInfo,
            changingUserInfo,
            changedUserInfo,
            userInfoUpdateErrors,
        } = this.state;

        const { userInfo } = this.props;

        if (!userInfo) {
            return null;
        }

        const userInfoUpdateErrorElements = [];
        userInfoUpdateErrors.forEach((userInfoUpdateError) => {
            userInfoUpdateErrorElements.push(
                <li key={userInfoUpdateError}>{userInfoUpdateError}</li>
            );
        });

        return (
            <Container text>
                <Header as="h2">Settings</Header>
                {userInfoUpdateErrorElements.length > 0 && (
                    <Message
                        error
                        header="Error changing settings"
                        content={<ul>{userInfoUpdateErrorElements}</ul>}
                    />
                )}
                {changedUserInfo && (
                    <Message success header="Settings updated!" />
                )}
                <Form
                    loading={changingUserInfo}
                    onSubmit={this.handleUpdateUserInfoSubmit}
                >
                    <Header as="h4" dividing>
                        Personal Information
                    </Header>
                    <Form.Input
                        label="Name"
                        id="name"
                        name="name"
                        type="text"
                        value={newUserInfo.name}
                        onChange={this.updateUserInfoField}
                    />
                    <Message attached="bottom">
                        We do not insist that you use your real name. If you
                        leave the name blank, your email address will be
                        substituted in its place where names are used, such as
                        on your measurements page.
                    </Message>
                    <Form.Input
                        label="Birthday"
                        id="birthday"
                        name="birthday"
                        type="date"
                        value={newUserInfo.birthday}
                        onChange={this.updateUserInfoField}
                    />
                    <Message attached="bottom">
                        Your birthday is used to estimate your basal metabolic
                        rate, daily energy expenditure and maximal heart rate.
                        If you do not wish to supply us with this information,
                        you can leave the value unset. You will not be given
                        basal metabolic rate, daily energy expenditure and
                        maximal heart rate estimations.
                    </Message>
                    <Form.Field
                        label="Sex"
                        id="sex"
                        name="sex"
                        control="select"
                        value={newUserInfo.sex}
                        onChange={(e) =>
                            this.updateUserInfoField(e, {
                                name: e.target.name,
                                value: e.target.value,
                            })
                        }
                    >
                        <option value="">Not set</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </Form.Field>
                    <Message attached="bottom">
                        We need to know your sex to estimate your basal
                        metabolic rate, daily energy expenditure. If you do not
                        wish to supply us with this information, you can leave
                        the value as "Not set." You will not be given basal
                        metabolic rate and daily energy expenditure estimations.
                    </Message>
                    <Header as="h4" dividing>
                        Unit preferences
                    </Header>
                    <Form.Field
                        label="Energy unit"
                        id="energyMeasurement"
                        name="energyMeasurement"
                        control="select"
                        value={newUserInfo.energyMeasurement}
                        onChange={(e) =>
                            this.updateUserInfoField(e, {
                                name: e.target.name,
                                value: e.target.value,
                            })
                        }
                    >
                        <option value="KJ">kilojoules (kJ)</option>
                        <option value="KCal">kilocalories (kcal)</option>
                    </Form.Field>
                    <Form.Field
                        label="Height unit(s)"
                        id="heightMeasurement"
                        name="heightMeasurement"
                        control="select"
                        value={newUserInfo.heightMeasurement}
                        onChange={(e) =>
                            this.updateUserInfoField(e, {
                                name: e.target.name,
                                value: e.target.value,
                            })
                        }
                    >
                        <option value="Cm">centimeters (cm)</option>
                        <option value="FtIn">feet and inches (ft. in.)</option>
                    </Form.Field>
                    <Form.Field
                        label="Weight unit(s)"
                        id="weightMeasurement"
                        name="weightMeasurement"
                        control="select"
                        value={newUserInfo.weightMeasurement}
                        onChange={(e) =>
                            this.updateUserInfoField(e, {
                                name: e.target.name,
                                value: e.target.value,
                            })
                        }
                    >
                        <option value="Kg">kilograms (kg)</option>
                        <option value="Lbs">pounds (lbs)</option>
                        <option value="StLbs">
                            stones and pounds (st. lbs.)
                        </option>
                    </Form.Field>
                    <Message attached="bottom">
                        Because the application uses kilograms internally,
                        values in other units are derived from these when
                        displaying them to you. You may therefore notice slight
                        rounding errors when not using kilograms as your weight
                        unit.
                    </Message>
                    <Button type="submit">Update settings</Button>
                </Form>
            </Container>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
