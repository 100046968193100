import { Nullable } from 'client/helpers';

import { MonthByNumbers, RangeByNumbers, YearByNumbers } from './DateByNumbers';

export enum Sex {
    Male = 'Male',
    Female = 'Female',
}

export enum EnergyMeasurement {
    KJ = 'KJ',
    KCal = 'KCal',
}

export enum HeightMeasurement {
    Cm = 'Cm',
    FtIn = 'FtIn',
}

export enum WeightMeasurement {
    Kg = 'Kg',
    Lbs = 'Lbs',
    StLbs = 'StLbs',
}

export enum ActivityLevel {
    Sedentary = 'Sedentary',
    LightlyActive = 'LightlyActive',
    ModeratelyActive = 'ModeratelyActive',
    VeryActive = 'VeryActive',
    ExtraActive = 'ExtraActive',
}

export enum MeasurementViewType {
    ByMonth,
    ByYear,
    Range,
    Last30Days,
    All,
}

type MeasurementViewOptionShared = {
    id: number;
    name: string;
};
type MeasurementViewOptionByMonth = {
    type: MeasurementViewType.ByMonth;
    value: MonthByNumbers;
};
type MeasurementViewOptionByYear = {
    type: MeasurementViewType.ByYear;
    value: YearByNumbers;
};
type MeasurementViewOptionRange = {
    type: MeasurementViewType.Range;
    value: Nullable<RangeByNumbers>;
};
type MeasurementViewOptionLast30Days = {
    type: MeasurementViewType.Last30Days;
    value?: undefined;
};
type MeasurementViewOptionAll = {
    type: MeasurementViewType.All;
    value?: undefined;
};

export type MeasurementViewValue =
    | MeasurementViewOptionByMonth
    | MeasurementViewOptionByYear
    | MeasurementViewOptionRange
    | MeasurementViewOptionLast30Days
    | MeasurementViewOptionAll;

export type MeasurementViewOption = MeasurementViewOptionShared &
    MeasurementViewValue;

export type User = {
    id: string;
    email: string;
    emailConfirmed: boolean;
    name: string | null;
    birthday: Date | null;
    sex: Sex | null;
    height: number | null;
    upperWeight: number | null;
    lowerWeight: number | null;
    energyMeasurement: EnergyMeasurement;
    heightMeasurement: HeightMeasurement;
    weightMeasurement: WeightMeasurement;
    activityLevel: ActivityLevel | null;
    public: boolean;

    displayName: string;
    idealLowerWeight: number | null;
    idealUpperWeight: number | null;
    bmr: number | null;
    dee: number | null;

    defaultMeasurementViewOption: number;
    customMeasurementViewOptions: MeasurementViewOption[];
    measurementYears: number[];
};
