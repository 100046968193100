import { FormEvent, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import PasswordField from 'client/components/widgets/PasswordField';

export type ChangePasswordFormArgs = {
    /**
     * The e-mail of the account whose password is being changed.
     * Used to help the browser's autocomplete/password manager.
     */
    email: string;
    /**
     * Whether the password is currently being changed. Activates
     * a loading spinner over the form.
     */
    isUpdatingPassword: boolean;
    /**
     * Submit handler for the form.
     */
    onSubmit: (
        event: React.FormEvent<HTMLFormElement>,
        data: {
            currentPassword: string;
            newPassword: string;
        },
        clear: () => void
    ) => void;
};

export default function ChangePasswordForm({
    email,
    isUpdatingPassword,
    onSubmit,
}: ChangePasswordFormArgs) {
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');

    const [currentPasswordError, setCurrentPasswordError] = useState<
        null | string
    >(null);
    const [newPasswordError, setNewPasswordError] = useState<null | string>(
        null
    );

    function onFormSubmit(e: FormEvent<HTMLFormElement>) {
        setCurrentPasswordError(null);
        setNewPasswordError(null);

        let hasError = false;
        if (!currentPassword) {
            setCurrentPasswordError('Current password cannot be empty');
            hasError = true;
        }

        if (!newPassword) {
            setNewPasswordError('New password cannot be empty');
            hasError = true;
        }

        if (hasError) {
            e.preventDefault();
            return;
        }

        const passwordData = {
            currentPassword,
            newPassword,
        };
        onSubmit(e, passwordData, () => {
            setCurrentPassword('');
            setNewPassword('');
        });
    }

    return (
        <Form loading={isUpdatingPassword} onSubmit={onFormSubmit}>
            <input
                type="email"
                autoComplete="username"
                value={email}
                style={{ display: 'none' }}
                readOnly
            />
            <PasswordField
                password={currentPassword}
                onPasswordChange={setCurrentPassword}
                passwordError={currentPasswordError}
                label="Current Password"
                autoComplete="current-password"
            />
            <PasswordField
                password={newPassword}
                onPasswordChange={setNewPassword}
                passwordError={newPasswordError}
                label="New Password"
                autoComplete="new-password"
            />
            <Button type="submit">Change</Button>
        </Form>
    );
}
