export function getDateAfterSeconds(seconds: number) {
    const date = new Date();
    date.setSeconds(date.getSeconds() + seconds);
    return date;
}

export function formatDate(date: Date | string) {
    return new Date(date).toLocaleDateString(undefined, {
        dateStyle: 'medium' as const,
    });
}

export function formatMonth(date: Date | string) {
    return new Date(date).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
    });
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export type Nullable<T> = {
    [P in keyof T]: T[P] | null;
};

export function isPromise(p: any): p is Promise<unknown> {
    if (typeof p === 'object' && typeof p.then === 'function') {
        return true;
    }

    return false;
}
