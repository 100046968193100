import { useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';

import './YearSelector.css';

type YearSelectorArgs = {
    label: string;

    years: number[];

    onYearChange: (newValue: number) => void;
    value: number;
};

export default function YearSelector({
    label,
    years,
    onYearChange,
    value,
}: YearSelectorArgs) {
    const yearOptions = useMemo(
        () =>
            years.map((y) => ({
                key: y,
                value: y,
                text: y.toString(),
            })),
        [years]
    );

    return (
        <>
            <label>Year: </label>
            <Dropdown
                className="YearSelector"
                selection
                options={yearOptions}
                value={value}
                onChange={(_, { value }) => {
                    onYearChange(value as number);
                }}
            ></Dropdown>
        </>
    );
}
