import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo, useState } from 'react';

import { ApiResult } from './services/http';

export function useTransferValueEffect(
    newValue: any,
    condition: () => boolean,
    transfer: () => void,
    reset: () => void
) {
    useEffect(() => {
        //let timeout: ReturnType<typeof setTimeout> | null = null;
        if (newValue !== null) {
            if (condition()) {
                transfer();
                reset();
            }
        }
    }, [newValue, condition, transfer, reset]);
}

// From: https://usehooks.com/useWindowSize/
export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<{
        width: undefined | number;
        height: undefined | number;
    }>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

export function useErrors(error: FetchBaseQueryError | SerializedError | null) {
    return useMemo(() => {
        const errors: string[] = [];
        if (error) {
            if ('status' in error) {
                switch (error.status) {
                    case 'PARSING_ERROR':
                        const errorApiResult = JSON.parse(
                            error.data
                        ) as ApiResult<unknown>;
                        errorApiResult.errors.forEach((error) => {
                            errors.push(error);
                        });
                        break;

                    case 'FETCH_ERROR':
                        errors.push(
                            'Could not communicate with the server. Make sure you have a valid internet connection.'
                        );
                        break;

                    default:
                        throw error;
                }
            } else {
                throw error;
            }
        }
        return errors;
    }, [error]);
}
