import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { createLogger } from 'redux-logger';

import authenticationReducer from './authenticationSlice';
import sidebarReducer from './sidebarSlice';
import userReducer from './userSlice';

import { weightMonitorApi } from './api';

const logger = createLogger();

const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        sidebar: sidebarReducer,
        user: userReducer,

        [weightMonitorApi.reducerPath]: weightMonitorApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(logger)
            .concat(weightMonitorApi.middleware),
});

setupListeners(store.dispatch);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
