import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Container, Header, Message } from 'semantic-ui-react';
import { AppDispatch, RootState } from '../../../redux/store';

import { updatePassword, UpdatePasswordArgs } from '../../../redux/userSlice';
import ChangePasswordForm from './components/ChangePasswordForm';

const mapState = (state: RootState) => {
    return {
        /**
         * The `User` object for the application.
         */
        userInfo: state.user.userInfo,
        /**
         * Whether the password is currently being updated.
         */
        isUpdatingPassword: state.user.isUpdatingPassword,
        /**
         * Errors encountered when attempting to update the password.
         */
        updatePasswordErrors: state.user.updatePasswordErrors,
    };
};

const mapDispatch = (dispatch: AppDispatch) => {
    return {
        /**
         * Update password handler for the page.
         */
        onUpdatePassword: ({
            currentPassword,
            newPassword,
        }: UpdatePasswordArgs) => {
            return dispatch(
                updatePassword({
                    currentPassword,
                    newPassword,
                })
            );
        },
    };
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type AccountSecurityProps = PropsFromRedux & {};

export function AccountSecurity({
    userInfo,
    isUpdatingPassword,
    updatePasswordErrors,
    onUpdatePassword,
}: AccountSecurityProps) {
    const [changedPassword, setChangedPassword] = useState(false);

    if (!userInfo) {
        return null;
    }

    const passwordErrorElements: JSX.Element[] = [];
    updatePasswordErrors.forEach((passwordError) => {
        passwordErrorElements.push(
            <li key={passwordError}>{passwordError}</li>
        );
    });
    return (
        <Container text>
            <Header as="h2">Account Security</Header>
            <Header as="h4" dividing>
                Change Password
            </Header>
            {updatePasswordErrors.length > 0 && (
                <Message
                    error
                    header="Error changing password"
                    content={<ul>{passwordErrorElements}</ul>}
                />
            )}
            {changedPassword && <Message success header="Password changed!" />}

            <ChangePasswordForm
                email={userInfo.email}
                isUpdatingPassword={isUpdatingPassword}
                onSubmit={async (_, passwordData, clear) => {
                    let result = await onUpdatePassword(passwordData);
                    if (result.payload === null) {
                        setChangedPassword(true);
                        clear();
                    }
                }}
            />
        </Container>
    );
}

export default connector(AccountSecurity);
