import {
    EnergyMeasurement,
    HeightMeasurement,
    WeightMeasurement,
} from './models/User';

export const KJPerKCal = 4.184;
export const KCalPerKJ = 1 / KJPerKCal;

export const kgPerLbs = 0.45359237;
export const lbsPerKg = 1 / kgPerLbs;
export const lbsPerSt = 14;

export const cmPerInch = 2.54;
export const inPerCm = 1 / cmPerInch;
export const ftPerIn = 12;

function round(num: number, precision: number) {
    return Number(
        Math.round(Number(num + 'e+' + precision)) + 'e-' + precision
    );
}

export function energy(kJ: number, measurementSystem: EnergyMeasurement) {
    switch (measurementSystem) {
        case EnergyMeasurement.KJ:
            return `${Math.round(kJ)} kJ`;
        case EnergyMeasurement.KCal:
            return `${Math.round(kJ * KCalPerKJ)} kcal`;
        default:
            throw new Error(`Unknown measurementSystem: ${measurementSystem}`);
    }
}

export function height(cm: number, measurementSystem: HeightMeasurement) {
    switch (measurementSystem) {
        case HeightMeasurement.Cm:
            return `${cm} cm`;
        case HeightMeasurement.FtIn:
            const totalInches = Math.round(cm * inPerCm);
            const feet = Math.floor(totalInches / ftPerIn);
            const inches = totalInches - feet * ftPerIn;
            return `${feet} ft. ${inches} in.`;
        default:
            throw new Error(`Unknown measurementSystem: ${measurementSystem}`);
    }
}

export function weight(
    kg: number,
    measurementSystem: WeightMeasurement,
    significantDecimals?: number
) {
    switch (measurementSystem) {
        case WeightMeasurement.Kg: {
            return `${weightValues(
                kg,
                measurementSystem,
                significantDecimals
            )} kg`;
        }
        case WeightMeasurement.Lbs: {
            return `${weightValues(
                kg,
                measurementSystem,
                significantDecimals
            )} lbs`;
        }
        case WeightMeasurement.StLbs: {
            const [stone, lbs] = weightValues(
                kg,
                measurementSystem,
                significantDecimals
            );
            if (stone !== 0) {
                return `${stone} st. ${lbs} lbs.`;
            } else {
                return `${lbs} lbs`;
            }
        }
        default:
            throw new Error(`Unknown measurementSystem: ${measurementSystem}`);
    }
}

export function rawWeight(
    value: number,
    measurementSystem: WeightMeasurement,
    significantDecimals?: number
) {
    if (significantDecimals === undefined) {
        significantDecimals = 1;
    }
    switch (measurementSystem) {
        case 'Kg':
            return `${value} kg`;
        case 'Lbs': {
            return `${value} lbs`;
        }
        case 'StLbs': {
            const [stone, lbs] = lbsToStoneLbs(value, significantDecimals);
            if (stone !== 0) {
                return `${stone} st. ${lbs} lbs.`;
            } else {
                return `${lbs} lbs`;
            }
        }
        default:
            throw new Error(`Unknown measurementSystem: ${measurementSystem}`);
    }
}

interface WeightMeasurementValueMap {
    [WeightMeasurement.Kg]: string;
    [WeightMeasurement.Lbs]: string;
    [WeightMeasurement.StLbs]: (string | number)[];
}

export function weightValues<MS extends WeightMeasurement>(
    kg: number,
    measurementSystem: MS,
    significantDecimals?: number
): WeightMeasurementValueMap[MS] {
    if (significantDecimals === undefined) {
        significantDecimals = 1;
    }
    switch (measurementSystem) {
        case 'Kg':
            return round(kg, significantDecimals).toFixed(
                significantDecimals
            ) as WeightMeasurementValueMap[MS];
        case 'Lbs': {
            const lbs = kg * lbsPerKg;
            return round(lbs, significantDecimals).toFixed(
                significantDecimals
            ) as WeightMeasurementValueMap[MS];
        }
        case 'StLbs': {
            const totalLbs = kg * lbsPerKg;
            return lbsToStoneLbs(
                totalLbs,
                significantDecimals
            ) as WeightMeasurementValueMap[MS];
        }
        default:
            throw new Error(`Unknown measurementSystem: ${measurementSystem}`);
    }
}
// export function weightValues(
//     kg: number,
//     measurementSystem: WeightMeasurement,
//     significantDecimals?: number
// ): string | (string | number)[] {
//     if (significantDecimals === undefined) {
//         significantDecimals = 1;
//     }
//     switch (measurementSystem) {
//         case 'Kg':
//             return round(kg, significantDecimals).toFixed(significantDecimals);
//         case 'Lbs': {
//             const lbs = kg * lbsPerKg;
//             return round(lbs, significantDecimals).toFixed(significantDecimals);
//         }
//         case 'StLbs': {
//             const totalLbs = kg * lbsPerKg;
//             return lbsToStoneLbs(totalLbs, significantDecimals);
//         }
//         default:
//             throw new Error(`Unknown measurementSystem: ${measurementSystem}`);
//     }
// }

function lbsToStoneLbs(totalLbs: number, significantDecimals: number) {
    const stone = Math.floor(totalLbs / lbsPerSt);
    let values;
    if (stone !== 0) {
        const lbs = totalLbs - stone * lbsPerSt;
        values = [stone, round(lbs, significantDecimals)];
    } else {
        values = [0, round(totalLbs, significantDecimals)];
    }
    if (values[1] >= 14) {
        values[0]++;
        values[1] -= 14;
    }
    return [values[0], values[1].toFixed(significantDecimals)];
}
