import React, { MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, SemanticICONS } from 'semantic-ui-react';

interface MenuItemProps {
    /**
     * The title of the menu item
     */
    title?: string;
    /**
     * The URL the menu item links to
     */
    location?: string;
    /**
     * Whether the URL is exact or not
     */
    exact?: boolean;
    /**
     * Optional menu item icon
     */
    icon?: SemanticICONS;
    /**
     * Optional click handler
     */
    onClick?: MouseEventHandler;
}

const MenuItem = ({
    title,
    location,
    exact = false,
    icon,
    onClick,
}: MenuItemProps) => {
    if (location) {
        return (
            <NavLink
                to={location}
                className="item"
                activeClassName="active"
                exact={exact}
            >
                <span onClick={(e) => onClick && onClick(e)}>
                    {icon && <Icon name={icon} />}
                    {title}
                </span>
            </NavLink>
        );
    } else {
        return (
            <a className="item" onClick={(e) => onClick && onClick(e)}>
                <span>
                    {icon && <Icon name={icon} />}
                    {title}
                </span>
            </a>
        );
    }
};

export default MenuItem;
