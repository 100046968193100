import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useMemo, useState } from 'react';
import { Button, Form, Icon, Message, Table } from 'semantic-ui-react';

import ApiErrorMessage from 'client/components/widgets/ApiErrorMessage';
import DateSelector from 'client/components/widgets/DateSelector';
import MeasurementInput from 'client/components/widgets/MeasurementInput';
import { isPromise } from 'client/helpers';
import {
    dateByNumbersAsIsoDateString,
    dateByNumbersFromJsDate,
} from 'client/models/DateByNumbers';
import { WeightMeasurement } from 'client/models/User';

import { MeasurementEntry } from '../../MeasurementsTable';
import './InsertMeasurement.css';

export type InsertMeasurementArgs = {
    weightMeasurement: WeightMeasurement;

    isReadOnly: boolean;
    isBusy: boolean;

    onInsertMeasurement: (
        measurement: MeasurementEntry
    ) => boolean | Promise<boolean>;
    insertingError: FetchBaseQueryError | SerializedError | null;
};

export default function InsertMeasurement({
    isReadOnly,
    isBusy,
    weightMeasurement,

    onInsertMeasurement,
    insertingError,
}: InsertMeasurementArgs) {
    function createWeightMeasurementEntry(): MeasurementEntry {
        const today = new Date().toISOString().substring(0, 10);
        switch (weightMeasurement) {
            case WeightMeasurement.Kg:
            case WeightMeasurement.Lbs:
                return {
                    type: weightMeasurement,
                    date: today,
                    weight: '',
                };

            case WeightMeasurement.StLbs:
                return {
                    type: WeightMeasurement.StLbs,
                    date: today,
                    weight: ['', ''],
                };
        }
    }

    const [measurementEntry, setMeasurementEntry] = useState<MeasurementEntry>(
        createWeightMeasurementEntry()
    );

    const [previousWeightMeasurement, setPreviousWeightMeasurement] =
        useState(weightMeasurement);
    if (weightMeasurement !== previousWeightMeasurement) {
        setMeasurementEntry(createWeightMeasurementEntry());
        setPreviousWeightMeasurement(weightMeasurement);
    }

    const [weightErrors, setWeightErrors] = useState<string[]>([]);
    async function insertMeasurement() {
        const newWeightErrors = [];
        switch (measurementEntry.type) {
            case WeightMeasurement.Kg:
            case WeightMeasurement.Lbs:
                if (
                    !measurementEntry.weight ||
                    isNaN(Number(measurementEntry.weight))
                ) {
                    newWeightErrors.push('Weight is not a valid number');
                }
                setWeightErrors(newWeightErrors);
                break;

            case WeightMeasurement.StLbs:
                if (
                    !measurementEntry.weight[0] ||
                    isNaN(Number(measurementEntry.weight[0]))
                ) {
                    newWeightErrors.push('Stone value is not a valid number');
                }
                if (
                    !measurementEntry.weight[1] ||
                    isNaN(Number(measurementEntry.weight[1]))
                ) {
                    newWeightErrors.push('Pounds value is not a valid number');
                }
                setWeightErrors(newWeightErrors);
                break;
        }
        if (newWeightErrors.length) {
            return;
        }

        let result = onInsertMeasurement(measurementEntry);
        if (isPromise(result)) {
            result = await result;
        }

        if (result) {
            // Insertion succeeded. Let's reset.
            setMeasurementEntry(createWeightMeasurementEntry());
        }
    }

    const insertionRows: JSX.Element[] = [];
    if (insertingError) {
        insertionRows.push(
            <Table.Row key="newErrors">
                <Table.Cell colSpan="5">
                    <ApiErrorMessage
                        error={insertingError}
                        header="Error entering measurement"
                    />
                </Table.Cell>
            </Table.Row>
        );
    } else if (weightErrors.length) {
        const errorElements = weightErrors.map((e) => <li key={e}>{e}</li>);
        insertionRows.push(
            <Table.Row key="newErrors">
                <Table.Cell colSpan="5">
                    <Message
                        error
                        header={'Error entering measurement'}
                        content={<ul>{errorElements}</ul>}
                    />
                </Table.Cell>
            </Table.Row>
        );
    }

    const measurementEntryDate = useMemo(
        () => dateByNumbersFromJsDate(new Date(measurementEntry.date)),
        [measurementEntry.date]
    );
    if (!isReadOnly) {
        const hideLabels = measurementEntry.type !== WeightMeasurement.StLbs;
        insertionRows.push(
            <Table.Row key="new" className="InsertMeasurementRow">
                <Table.Cell className="InsertMeasurementCell-Date">
                    <Form>
                        <DateSelector
                            name="date"
                            label="Date"
                            required
                            onDateChange={(value) =>
                                setMeasurementEntry({
                                    ...measurementEntry,
                                    date: dateByNumbersAsIsoDateString(value),
                                })
                            }
                            value={measurementEntryDate}
                            onEnterPress={() => insertMeasurement()}
                            disabled={isBusy}
                        />
                    </Form>
                </Table.Cell>
                <Table.Cell
                    colSpan="3"
                    className={
                        'InsertMeasurementCell-Input ' +
                        (hideLabels ? 'hideLabel' : '')
                    }
                >
                    <Form onSubmit={() => insertMeasurement()}>
                        <MeasurementInput
                            measurementValue={measurementEntry}
                            onMeasurementValueChange={(v) =>
                                setMeasurementEntry({
                                    ...v,
                                    date: measurementEntry.date,
                                })
                            }
                            disabled={isBusy}
                        />
                    </Form>
                </Table.Cell>
                <Table.Cell className="InsertMeasurementCell-Action actionCell">
                    <div>
                        <Button
                            loading={isBusy}
                            disabled={isBusy}
                            icon="add"
                            circular
                            onClick={() => insertMeasurement()}
                        />
                    </div>
                </Table.Cell>
            </Table.Row>
        );
    }

    return <>{insertionRows}</>;
}
