import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppDispatch, RootState } from '../../redux/store';
import { toggleSidebar } from '../../redux/sidebarSlice';

import MenuItem from './MenuItem';

const mapState = (state: RootState) => {
    return {
        visible: state.sidebar.visible,
    };
};

const mapDispatch = (dispatch: AppDispatch) => {
    return {
        onClick: () => dispatch(toggleSidebar()),
    };
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SidebarTogglerProps = PropsFromRedux & {};

export const SidebarToggler = ({ onClick }: SidebarTogglerProps) => {
    return <MenuItem icon="bars" onClick={() => onClick()} />;
};

export default connector(SidebarToggler);
