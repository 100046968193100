import React, { ReactElement } from 'react';
import { Button, Modal } from 'semantic-ui-react';

export type ConfirmationModalArgs = {
    onCancel: () => void;
    open: boolean;
    isPerforming: boolean;
    children: React.ReactNode;
    header: string;
    cancelText?: string;
    confirmButton: ReactElement<Button>;
};

export default function ConfirmationModal({
    onCancel,
    open,
    isPerforming,
    children,
    header,
    confirmButton,
    cancelText,
}: ConfirmationModalArgs) {
    return (
        <Modal
            size="tiny"
            onClose={() => onCancel()}
            open={open}
            closeOnDimmerClick={!isPerforming}
            closeOnEscape={!isPerforming}
        >
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content>{children}</Modal.Content>
            <Modal.Actions>
                <Button.Group>
                    <Button onClick={() => onCancel()} disabled={isPerforming}>
                        {cancelText ?? 'Cancel'}
                    </Button>
                    <Button.Or />
                    {confirmButton}
                </Button.Group>
            </Modal.Actions>
        </Modal>
    );
}
