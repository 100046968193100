import { Form } from 'semantic-ui-react';

import './MonthSelector.css';

type MonthSharedArgs = {
    name?: string;
    label: string;
};

type MonthOptionalArgs = MonthSharedArgs & {
    required?: false;

    onMonthChange: (newValue: { year: number; month: number } | null) => void;

    value: { year: number; month: number } | null;
};

type MonthRequiredArgs = MonthSharedArgs & {
    required: true;

    onMonthChange: (newValue: { year: number; month: number }) => void;

    value: { year: number; month: number };
};

export type MonthSelectorArgs = MonthOptionalArgs | MonthRequiredArgs;

export default function MonthSelector(args: MonthSelectorArgs) {
    const name = args.name ? args.name : 'month';
    const required = args.required ? true : false;

    const value = args.value
        ? `${args.value.year}-${args.value.month.toString().padStart(2, '0')}`
        : '';
    return (
        <Form.Input
            className="MonthSelector"
            label={args.label}
            name={name}
            inline
            type="month"
            value={value}
            onChange={(_, { value }) => {
                if (!args.required && !value) {
                    args.onMonthChange(null);
                } else {
                    const date = new Date(Date.parse(value));
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    args.onMonthChange({
                        year: Number(year),
                        month: Number(month),
                    });
                }
            }}
            required={required}
        />
    );
}
