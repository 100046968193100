import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect, useRef, useState } from 'react';
import { Button, Form, Message, Ref, Segment } from 'semantic-ui-react';

import ApiErrorMessage from 'client/components/widgets/ApiErrorMessage';
import ConfirmationModal from 'client/components/widgets/ConfirmationModal';
import { formatMonth } from 'client/helpers';
import {
    MeasurementViewOption,
    MeasurementViewType,
    MeasurementViewValue,
} from 'client/models/User';

function generateDefaultCustomViewName(
    measurementViewOption: MeasurementViewValueWithName
): [string, boolean] {
    switch (measurementViewOption.type) {
        case MeasurementViewType.ByMonth:
            const date = new Date(
                measurementViewOption.value.year,
                measurementViewOption.value.month - 1
            );
            return [formatMonth(date), false];

        case MeasurementViewType.ByYear:
            return [measurementViewOption.value.year.toString(), false];

        default:
            return [`Custom ${measurementViewOption.name}`, true];
    }
}

type MeasurementViewValueWithName = MeasurementViewValue & { name: string };

export type SaveAsCustomViewModalArgs = {
    measurementViewValue: MeasurementViewValueWithName | null;
    customMeasurementViewOptions: MeasurementViewOption[];

    onCancel: () => void;
    onConfirm: (customViewData: string | { id: number; name: string }) => void;
    savingError: FetchBaseQueryError | SerializedError | null;
    isSaving: boolean;
};

let customViewNameOnOpenState = false;
export default function SaveAsCustomViewModal({
    measurementViewValue,
    customMeasurementViewOptions,

    onCancel,
    onConfirm,
    savingError,
    isSaving,
}: SaveAsCustomViewModalArgs) {
    const [previousMeasurementViewValue, setPreviousMeasurementViewValue] =
        useState<MeasurementViewValueWithName | null>(null);
    const [customViewName, setCustomViewName] = useState('');
    const [selectCustomViewNameOnOpen, setSelectCustomViewNameOnOpen] =
        useState<boolean | null>(null);

    if (measurementViewValue !== previousMeasurementViewValue) {
        if (measurementViewValue !== null) {
            const [defaultCustomViewName, selectCustomViewName] =
                generateDefaultCustomViewName(measurementViewValue);
            setCustomViewName(defaultCustomViewName);
            if (selectCustomViewName) {
                customViewNameOnOpenState = !customViewNameOnOpenState;
                setSelectCustomViewNameOnOpen(customViewNameOnOpenState);
            } else {
                setSelectCustomViewNameOnOpen(null);
            }
        }
        setPreviousMeasurementViewValue(measurementViewValue);
    }
    const customViewNameInputRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (selectCustomViewNameOnOpen !== null) {
            const inputElement =
                customViewNameInputRef.current?.querySelector('input');
            if (inputElement) {
                inputElement.select();
            } else {
                console.error(
                    'Should not be possible to get here with null value, but:',
                    customViewNameInputRef.current,
                    selectCustomViewNameOnOpen
                );
            }
        }
    }, [selectCustomViewNameOnOpen]);

    const [confirmOverwrite, setConfirmOverwrite] = useState<number | null>(
        null
    );
    const [temporarilyDisableSave, setTemporarilyDisableSave] =
        useState<number>(0);
    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout> | null = null;

        function countdown() {
            if (temporarilyDisableSave) {
                timeout = setTimeout(() => {
                    setTemporarilyDisableSave((v) => v - 1);
                }, 1000);
            }
        }
        countdown();

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [temporarilyDisableSave]);

    function onAttemptConfirm() {
        const customMeasurementViewOptionWithSameName =
            customMeasurementViewOptions.find((m) => m.name === customViewName);
        if (!confirmOverwrite && customMeasurementViewOptionWithSameName) {
            setConfirmOverwrite(customMeasurementViewOptionWithSameName.id);
            setTemporarilyDisableSave(2);
        } else if (
            confirmOverwrite &&
            customMeasurementViewOptionWithSameName
        ) {
            setConfirmOverwrite(null);
            onConfirm({ id: confirmOverwrite, name: customViewName });
        } else {
            setConfirmOverwrite(null);
            onConfirm(customViewName);
        }
    }

    return (
        <ConfirmationModal
            onCancel={onCancel}
            open={measurementViewValue !== null}
            isPerforming={isSaving}
            header="Save As Custom View"
            confirmButton={
                <Button
                    content="Save"
                    labelPosition="right"
                    icon={
                        temporarilyDisableSave === 0
                            ? {
                                  name: isSaving ? 'spinner' : 'save',
                                  loading: isSaving,
                              }
                            : undefined
                    }
                    label={
                        temporarilyDisableSave > 0
                            ? {
                                  content: temporarilyDisableSave,
                                  color: 'blue',
                                  basic: false,
                                  icon: 'save',
                                  pointing: false,
                              }
                            : undefined
                    }
                    primary
                    onClick={onAttemptConfirm}
                    disabled={isSaving || temporarilyDisableSave > 0}
                />
            }
        >
            <Segment basic>
                <ApiErrorMessage
                    error={savingError}
                    header="Error saving custom view"
                />
                {confirmOverwrite ? (
                    <Message
                        warning
                        header={`Overwrite existing custom view "${customViewName}"?`}
                        content='Hit the "Save" button again to confirm.'
                    />
                ) : null}
                <Ref innerRef={customViewNameInputRef as any}>
                    <Form.Input
                        label="Custom View Name"
                        type="text"
                        autoFocus
                        value={customViewName}
                        onChange={(_, { value }) => {
                            setCustomViewName(value);
                            setConfirmOverwrite(null);
                        }}
                        onKeyUp={(event: KeyboardEvent) => {
                            if (event.key === 'Enter') {
                                onAttemptConfirm();
                            }
                        }}
                        disabled={isSaving}
                    ></Form.Input>
                </Ref>
            </Segment>
        </ConfirmationModal>
    );
}
