import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useMemo } from 'react';
import { Button, Icon, Popup, Table } from 'semantic-ui-react';

import ActionPopupMenu from 'client/components/widgets/ActionPopupMenu';
import ApiErrorMessage from 'client/components/widgets/ApiErrorMessage';
import { weight as renderWeight } from 'client/conversions';
import { formatDate } from 'client/helpers';
import { PresentMeasurement } from 'client/models/Measurement';
import { WeightMeasurement } from 'client/models/User';

function useRenderedMeasurements(
    measurement: PresentMeasurement,
    weightMeasurement: WeightMeasurement
) {
    return useMemo(() => {
        return [
            formatDate(measurement.date),
            renderWeight(measurement.weight, weightMeasurement),
            renderWeight(measurement.trend, weightMeasurement),
            `${measurement.variance < 0 ? '-' : ''}${renderWeight(
                Math.abs(measurement.variance),
                weightMeasurement
            )}`,
        ];
    }, [measurement, weightMeasurement]);
}

export type MeasurementComponentArgs = {
    isReadOnly: boolean;
    currentlyDeletingMeasurement: Pick<PresentMeasurement, 'date'> | null;
    measurement: Readonly<PresentMeasurement>;
    weightMeasurement: WeightMeasurement;

    onEditMeasurement: (measurement: Readonly<PresentMeasurement>) => void;
    onDeleteMeasurement: (measurement: Readonly<PresentMeasurement>) => void;
    deletingError: FetchBaseQueryError | SerializedError | null;
};

export default function MeasurementComponent({
    isReadOnly,
    currentlyDeletingMeasurement,
    measurement,
    weightMeasurement,

    onEditMeasurement,
    onDeleteMeasurement,
    deletingError,
}: MeasurementComponentArgs) {
    const deletionRows: JSX.Element[] = [];
    if (deletingError) {
        deletionRows.push(
            <Table.Row key="deleteErrors">
                <Table.Cell colSpan="5">
                    <ApiErrorMessage
                        error={deletingError}
                        header="Error deleting measurement"
                    />
                </Table.Cell>
            </Table.Row>
        );
    }

    const isDeleting =
        currentlyDeletingMeasurement !== null &&
        currentlyDeletingMeasurement.date === measurement.date;
    const [date, weight, trend, variance] = useRenderedMeasurements(
        measurement,
        weightMeasurement
    );
    deletionRows.push(
        <Table.Row className="MeasurementRow" key={measurement.date}>
            <Table.Cell className="MeasurementCell-Date">{date}</Table.Cell>
            <Table.Cell className="MeasurementCell-Weight">{weight}</Table.Cell>
            <Table.Cell className="MeasurementCell-Trend">{trend}</Table.Cell>
            <Table.Cell className="MeasurementCell-Variance">
                {variance}
            </Table.Cell>
            <Table.Cell
                className={`MeasurementCell-Action actionCell ${
                    isReadOnly ? 'hide' : ''
                }`}
            >
                {isReadOnly ? (
                    <></>
                ) : (
                    <div>
                        <ActionPopupMenu
                            isBusy={isDeleting}
                            menuItems={[
                                {
                                    key: 'edit',
                                    name: 'edit',
                                    content: 'Edit Measurement',
                                    icon: 'edit',
                                    onClick: () =>
                                        onEditMeasurement(measurement),
                                },
                                {
                                    key: 'delete',
                                    name: 'delete',
                                    content: 'Delete Measurement',
                                    icon: {
                                        name: 'trash alternate outline',
                                        color: 'red',
                                    },
                                    onClick: () =>
                                        onDeleteMeasurement(measurement),
                                },
                            ]}
                        />
                    </div>
                )}
            </Table.Cell>
        </Table.Row>
    );

    return <>{deletionRows}</>;
}
