import React from 'react';
import {
    Grid,
    Header,
    Icon,
    List,
    Placeholder,
    Popup,
    Segment,
} from 'semantic-ui-react';

import {
    energy as renderEnergy,
    weight as renderWeight,
} from 'client/conversions';
import { formatDate } from 'client/helpers';
import { Trends } from 'client/models/Trends';
import { User } from 'client/models/User';

import './Trends.css';

export type TrendsProps = {
    energyMeasurement: User['energyMeasurement'] | null;
    weightMeasurement: User['weightMeasurement'] | null;
    trends: Trends | null;
    isLoading: boolean;
};

export default function TrendsComponent({
    energyMeasurement,
    weightMeasurement,
    trends,
    isLoading,
}: TrendsProps) {
    let timeUntilIdealWeightComponent: JSX.Element | null = null;
    let dateIdealWeightComponent: JSX.Element | null = null;
    if (trends !== null) {
        if (
            trends.timeUntilIdealWeight !== null &&
            trends.timeUntilIdealWeight.years < 100
        ) {
            const timeComponents: JSX.Element[] = [];
            let first = true;
            if (trends.timeUntilIdealWeight.years) {
                timeComponents.push(
                    <span key="years" className="nowrap">
                        {trends.timeUntilIdealWeight.years}{' '}
                        <abbr title="years">y</abbr>
                    </span>
                );
                first = false;
            }
            if (trends.timeUntilIdealWeight.months) {
                timeComponents.push(
                    <span key="months">
                        {!first && ', '}
                        <span className="nowrap">
                            {trends.timeUntilIdealWeight.months}{' '}
                            <abbr title="months">m</abbr>
                        </span>
                    </span>
                );
                first = false;
            }
            if (trends.timeUntilIdealWeight.weeks) {
                timeComponents.push(
                    <span key="weeks">
                        {!first && ', '}
                        <span className="nowrap">
                            {trends.timeUntilIdealWeight.weeks}{' '}
                            <abbr title="weeks">w</abbr>
                        </span>
                    </span>
                );
                first = false;
            }
            if (trends.timeUntilIdealWeight.days) {
                timeComponents.push(
                    <span key="days">
                        {!first && ', '}
                        <span className="nowrap">
                            {trends.timeUntilIdealWeight.days}{' '}
                            <abbr title="days">d</abbr>
                        </span>
                    </span>
                );
            }
            timeUntilIdealWeightComponent = (
                <List.Item>
                    Time until ideal weight is reached: {timeComponents}
                </List.Item>
            );
        } else if (trends.isIdealWeight) {
            timeUntilIdealWeightComponent = (
                <List.Item>
                    Time until ideal weight is reached: <b>Reached!</b>
                </List.Item>
            );
        } else {
            timeUntilIdealWeightComponent = (
                <List.Item>Time until ideal weight is reached: Never</List.Item>
            );
        }

        if (
            trends.dateIdealWeightReached !== null &&
            trends.timeUntilIdealWeight !== null &&
            trends.timeUntilIdealWeight.years < 100
        ) {
            dateIdealWeightComponent = (
                <List.Item>
                    Date ideal weight is reached:{' '}
                    {formatDate(trends.dateIdealWeightReached)}
                </List.Item>
            );
        } else if (trends.isIdealWeight) {
            dateIdealWeightComponent = (
                <List.Item>
                    Date ideal weight is reached: <b>Reached!</b>
                </List.Item>
            );
        } else {
            dateIdealWeightComponent = (
                <List.Item>Date ideal weight is reached: Never</List.Item>
            );
        }
    }

    return (
        <>
            <Header as="div" attached="top">
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column className="vcentered-parent">
                            <h3 className="vcentered-text">Current trends</h3>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            {trends?.incompleteDataset && (
                                <Popup
                                    trigger={
                                        <Icon
                                            name="warning sign"
                                            color="yellow"
                                            circular
                                        />
                                    }
                                    content="These trends are based on an incomplete dataset and may not be very accurate"
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Header>
            <Segment attached="bottom">
                {isLoading ? (
                    <Placeholder>
                        <Placeholder.Line length="short" />
                        <Placeholder.Line length="long" />
                        <Placeholder.Line length="long" />
                        <Placeholder.Line length="long" />
                        <Placeholder.Line length="long" />
                    </Placeholder>
                ) : trends && energyMeasurement && weightMeasurement ? (
                    <List>
                        <List.Item>
                            Daily{' '}
                            {trends.dailyEnergyBalance > 0
                                ? 'excess'
                                : 'deficit'}
                            :{' '}
                            {renderEnergy(
                                Math.abs(trends.dailyEnergyBalance),
                                energyMeasurement
                            )}
                        </List.Item>
                        <List.Item>
                            Weight{' '}
                            {trends.weightChangePerWeek > 0 ? 'gain' : 'loss'}{' '}
                            per week/month:{' '}
                            {renderWeight(
                                Math.abs(trends.weightChangePerWeek),
                                weightMeasurement
                            )}{' '}
                            /{' '}
                            {renderWeight(
                                Math.abs(trends.weightChangePerMonth),
                                weightMeasurement
                            )}
                        </List.Item>
                        <List.Item>
                            {trends.distanceToIdealWeight > 0 ? 'Gain' : 'Loss'}{' '}
                            needed to reach ideal weight:{' '}
                            {renderWeight(
                                trends.distanceToIdealWeight,
                                weightMeasurement
                            )}
                        </List.Item>
                        {timeUntilIdealWeightComponent}
                        {dateIdealWeightComponent}
                    </List>
                ) : (
                    <p>No trends to show</p>
                )}
            </Segment>
        </>
    );
}
