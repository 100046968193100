import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { setRedirectUrl } from '../../redux/authenticationSlice';
import { AppDispatch, RootState } from '../../redux/store';

export function AuthWrapper<P>(
    Component: React.ComponentType<P>,
    requireAuth: boolean,
    redirectUrl = '/login'
) {
    const mapState = (state: RootState) => {
        return {
            isValidating: state.authentication.isValidating,
            isLoggedIn: state.authentication.isLoggedIn,
            userInfo: state.user.userInfo,
        };
    };

    const mapDispatch = (dispatch: AppDispatch) => {
        return {
            setRedirectUrl: (url: string) => {
                dispatch(setRedirectUrl(url));
            },
        };
    };

    const connector = connect(mapState, mapDispatch);
    type PropsFromRedux = ConnectedProps<typeof connector>;
    type AuthComponentProps = PropsFromRedux & RouteComponentProps & P;

    class AuthComponent extends React.Component<AuthComponentProps> {
        state: { originalPath: null | string } = {
            originalPath: null,
        };

        componentDidMount() {
            this.setState({
                originalPath: window.location.pathname,
            });
        }
        componentDidUpdate() {
            const { isLoggedIn, setRedirectUrl } = this.props;
            const isAuthenticated = isLoggedIn !== null && isLoggedIn;
            if (requireAuth && !isAuthenticated) {
                const { originalPath } = this.state;
                if (originalPath) {
                    setRedirectUrl(originalPath);
                }
            }
        }

        render() {
            const { isLoggedIn, isValidating, userInfo } = this.props;
            const isAuthenticated = isLoggedIn !== null && isLoggedIn;

            if (isValidating) {
                return <Loader active>Verifying authentication...</Loader>;
            }
            if (isAuthenticated && !userInfo) {
                return <Loader active>Fetching user data...</Loader>;
            }
            if (
                (requireAuth && !isAuthenticated) ||
                (!requireAuth && isAuthenticated)
            ) {
                return <Redirect to={redirectUrl} />;
            }

            return <Component {...this.props} />;
        }
    }

    // TODO: Fix `any` hack
    return connector(AuthComponent as any);
}

function RequireAuth<P>(Component: React.ComponentType<P>) {
    return AuthWrapper<P>(Component, true);
}

function RequireNotAuth<P>(
    Component: React.ComponentType<P>,
    authRedirectUrl: string
) {
    return AuthWrapper(Component, false, authRedirectUrl);
}

export { RequireAuth, RequireNotAuth };
