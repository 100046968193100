import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useMemo, useState } from 'react';
import { Placeholder, Table } from 'semantic-ui-react';

import { kgPerLbs, lbsPerSt } from 'client/conversions';
import { Measurement, PresentMeasurement } from 'client/models/Measurement';
import { WeightMeasurement } from 'client/models/User';

import './MeasurementsTable.css';
import EditMeasurement from './components/EditMeasurement/EditMeasurement';
import InsertMeasurement from './components/InsertMeasurement/InsertMeasurement';
import MeasurementComponent from './components/Measurement/MeasurementComponent';

export type MeasurementEntryWeight =
    | {
          type: WeightMeasurement.Kg | WeightMeasurement.Lbs;
          weight: string;
      }
    | {
          type: WeightMeasurement.StLbs;
          weight: [string, string];
      };

export type MeasurementEntry = { date: string } & MeasurementEntryWeight;

export function getWeightInKgFromMeasurementEntry(m: MeasurementEntry) {
    let weight;
    switch (m.type) {
        case WeightMeasurement.Kg:
            weight = Number(m.weight);
            break;
        case WeightMeasurement.Lbs:
            weight = Number(m.weight) * kgPerLbs;
            break;
        case WeightMeasurement.StLbs:
            const totalLbs =
                Number(m.weight[0]) * lbsPerSt + Number(m.weight[1]);
            weight = totalLbs * kgPerLbs;
            break;
    }
    return weight;
}

export type MeasurementsTableArgs = {
    isReadOnly: boolean;
    estimatedRowCountAfterLoading: number | null;
    isInserting: boolean;
    isUpdating: boolean;
    currentlyDeletingMeasurement: Pick<PresentMeasurement, 'date'> | null;
    measurements: Measurement[];
    weightMeasurement: WeightMeasurement;

    onInsertMeasurement: (
        measurement: MeasurementEntry
    ) => boolean | Promise<boolean>;
    insertingError: FetchBaseQueryError | SerializedError | null;

    onEditMeasurement: (
        measurement: MeasurementEntry
    ) => boolean | Promise<boolean>;
    editingError: FetchBaseQueryError | SerializedError | null;

    onDeleteMeasurement: (measurement: PresentMeasurement) => void;
    deletingError: FetchBaseQueryError | SerializedError | null;
};

export default function MeasurementsTable({
    isReadOnly,
    estimatedRowCountAfterLoading,
    isInserting,
    isUpdating,
    currentlyDeletingMeasurement,
    measurements,
    weightMeasurement,

    onInsertMeasurement,
    insertingError,

    onEditMeasurement,
    editingError,

    onDeleteMeasurement,
    deletingError,
}: MeasurementsTableArgs) {
    const [editingMeasurementDate, setEditingMeasurementDate] = useState<
        string | null
    >(null);

    const loadingRows = useMemo(() => {
        if (estimatedRowCountAfterLoading === null) {
            return [];
        }

        const loadingRows: JSX.Element[] = [];
        for (let index = 0; index < estimatedRowCountAfterLoading; index++) {
            loadingRows.push(
                <Table.Row key={index}>
                    <Table.Cell>
                        <Placeholder>
                            <Placeholder.Line />
                        </Placeholder>
                    </Table.Cell>
                    <Table.Cell>
                        <Placeholder>
                            <Placeholder.Line />
                        </Placeholder>
                    </Table.Cell>
                    <Table.Cell>
                        <Placeholder>
                            <Placeholder.Line />
                        </Placeholder>
                    </Table.Cell>
                    <Table.Cell>
                        <Placeholder>
                            <Placeholder.Line />
                        </Placeholder>
                    </Table.Cell>
                    <Table.Cell
                        className={`actionCell ${isReadOnly ? 'hide' : ''}`}
                    >
                        {isReadOnly ? (
                            <></>
                        ) : (
                            <Placeholder style={{ height: 24 }}>
                                <Placeholder.Image />
                            </Placeholder>
                        )}
                    </Table.Cell>
                </Table.Row>
            );
        }
        return loadingRows;
    }, [estimatedRowCountAfterLoading, isReadOnly]);

    const measurementRows: JSX.Element[] = [];
    if (estimatedRowCountAfterLoading === null) {
        measurementRows.push(
            <InsertMeasurement
                key="insertMeasurement"
                isReadOnly={isReadOnly}
                isBusy={isInserting}
                weightMeasurement={weightMeasurement}
                onInsertMeasurement={onInsertMeasurement}
                insertingError={insertingError}
            />
        );

        let lastWasPresent = true;
        let missingEntries = 0;
        measurements.forEach((measurement) => {
            if (!measurement.present && !lastWasPresent) {
                missingEntries++;
                return;
            }

            if (!measurement.present) {
                lastWasPresent = false;
                missingEntries = 1;
            } else {
                if (!lastWasPresent) {
                    // Insert empty entry
                    measurementRows.push(
                        <Table.Row key={`${measurement.date}-missing`}>
                            <Table.Cell
                                textAlign="center"
                                colSpan="5"
                                disabled={true}
                            >{`${missingEntries} missing day${
                                missingEntries > 1 ? 's' : ''
                            }`}</Table.Cell>
                        </Table.Row>
                    );
                }

                lastWasPresent = true;
                if (
                    editingMeasurementDate &&
                    measurement.date === editingMeasurementDate
                ) {
                    measurementRows.push(
                        <EditMeasurement
                            key="editMeasurement"
                            weightMeasurement={weightMeasurement}
                            measurement={measurement}
                            isSaving={isUpdating}
                            onEditMeasurement={async (m) => {
                                const succeeded = await onEditMeasurement(m);
                                if (succeeded) {
                                    setEditingMeasurementDate(null);
                                }
                                return succeeded;
                            }}
                            editingError={editingError}
                            onCancelEditMeasurement={() =>
                                setEditingMeasurementDate(null)
                            }
                        />
                    );
                } else {
                    measurementRows.push(
                        <MeasurementComponent
                            key={measurement.date}
                            weightMeasurement={weightMeasurement}
                            measurement={measurement}
                            isReadOnly={isReadOnly}
                            currentlyDeletingMeasurement={
                                currentlyDeletingMeasurement
                            }
                            onEditMeasurement={(m) =>
                                setEditingMeasurementDate(m.date)
                            }
                            onDeleteMeasurement={onDeleteMeasurement}
                            deletingError={deletingError}
                        />
                    );
                }
            }
        });
    } else {
        measurementRows.splice(0, 0, ...loadingRows);
    }

    return (
        <>
            <div style={{ position: 'relative' }}>
                <Table id="UserMeasurementTable">
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Weight</Table.HeaderCell>
                            <Table.HeaderCell>Trend</Table.HeaderCell>
                            <Table.HeaderCell>Variance</Table.HeaderCell>
                            <Table.HeaderCell
                                className={`actionCell ${
                                    isReadOnly ? 'hide' : ''
                                }`}
                            >
                                Actions
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{measurementRows}</Table.Body>
                </Table>
            </div>
        </>
    );
}
