import { uniqueId } from 'lodash';
import { useMemo, useState } from 'react';
import { Form } from 'semantic-ui-react';

export type PasswordFieldArgs = {
    password: string;
    onPasswordChange: (newPassword: string) => void;
    passwordError: null | string;

    name?: string;
    label: string;

    autoComplete: string;
};

export default function PasswordField({
    password,
    onPasswordChange,
    passwordError,
    label,
    name: nameProp,
    autoComplete,
}: PasswordFieldArgs) {
    const name = nameProp ? nameProp : 'password';
    const passwordId = useMemo(() => uniqueId(`${name}-`), [name]);

    const [seePassword, setSeePassword] = useState(false);

    return (
        <>
            <p
                role="status"
                aria-live="polite"
                id={passwordId + '-text'}
                className="sr-only"
            >
                {seePassword ? `${label} shown.` : `${label} hidden.`}
            </p>
            <Form.Input
                label={label}
                id={passwordId}
                name={nameProp ? name : passwordId}
                type={seePassword ? 'text' : 'password'}
                value={password}
                onChange={(_, { value }) => onPasswordChange(value)}
                autoComplete={autoComplete}
                error={passwordError}
                icon={{
                    name: seePassword ? 'eye slash' : 'eye',
                    circular: true,
                    link: true,
                    onClick: () => setSeePassword(!seePassword),

                    role: 'button',
                    'aria-hidden': 'false',
                    'aria-pressed': seePassword,
                }}
            />
        </>
    );
}
