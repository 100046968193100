import { SemanticICONS } from 'semantic-ui-react';

export class MenuItem {
    location: string;
    title: string;
    authenticated: boolean | null;
    icon: SemanticICONS | undefined;
    exact: boolean;

    constructor(
        location: string,
        title: string,
        authenticated = null,
        icon = undefined,
        exact = false
    ) {
        this.location = location;
        this.title = title;
        this.authenticated = authenticated;
        this.icon = icon;
        this.exact = exact;
    }
}

export default class Menu {
    namedMenuItems: { [name: string]: MenuItem } = {};

    menuItems: MenuItem[];

    constructor(menuItems = []) {
        this.menuItems = menuItems;
    }

    addMenuItem(menuItem: MenuItem) {
        this.menuItems.push(menuItem);
    }

    addNamedMenuitem(name: string, menuItem: MenuItem) {
        this.namedMenuItems[name] = menuItem;
    }
}
