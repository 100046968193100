import React, { MouseEventHandler } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import MenuItem from './MenuItem';

import { MenuItem as MenuItemModel } from '../../models/Menu';
import { RootState } from '../../redux/store';

const mapState = (state: RootState) => {
    return {
        isLoggedIn: state.authentication.isLoggedIn,
    };
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type MenuListProps = PropsFromRedux & {
    menuItems: MenuItemModel[];
    onClick?: MouseEventHandler;
};

export const MenuList = ({ menuItems, onClick, isLoggedIn }: MenuListProps) => {
    if (isLoggedIn === null) {
        return null;
    }

    return (
        <>
            {menuItems
                .filter((menuItem) => {
                    if (
                        (menuItem.authenticated === true && isLoggedIn) ||
                        (menuItem.authenticated === false && !isLoggedIn) ||
                        menuItem.authenticated === null
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .map((menuItem) => {
                    return (
                        <MenuItem
                            key={menuItem.location}
                            onClick={(e) => onClick && onClick(e)}
                            {...menuItem}
                        />
                    );
                })}
        </>
    );
};

export default connector(MenuList);
