import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Button,
    Container,
    Dimmer,
    Grid,
    Header,
    Icon,
    Segment,
} from 'semantic-ui-react';

import ApiErrorMessage from 'client/components/widgets/ApiErrorMessage';
import ConfirmationModal from 'client/components/widgets/ConfirmationModal';
import {
    BY_MONTH_ID,
    BY_YEAR_ID,
    RANGE_ID,
    useFindMeasurementViewOption,
} from 'client/components/widgets/MeasurementViewDropDown';
import MeasurementViewSelector from 'client/components/widgets/MeasurementViewSelector';
import { formatDate } from 'client/helpers';
import { useTransferValueEffect } from 'client/hooks';
import { PresentMeasurement } from 'client/models/Measurement';
import {
    MeasurementViewOption,
    MeasurementViewType,
    MeasurementViewValue,
} from 'client/models/User';
import {
    useDeleteCustomViewMutation,
    useDeleteMeasurementMutation,
    useGetMeasurementsQuery,
    useGetTrendsQuery,
    useGetUserInfoQuery,
    useInsertCustomViewMutation,
    useInsertMeasurementMutation,
    useSetDefaultCustomViewMutation,
    useUpdateCustomViewMutation,
    useUpdateMeasurementMutation,
} from 'client/redux/api';
import { ApiResult } from 'client/services/http';
import { setTitle } from 'client/services/title';

import './User.css';
import MeasurementsTable, {
    MeasurementEntry,
    getWeightInKgFromMeasurementEntry,
} from './components/MeasurementsTable/MeasurementsTable';
import SaveAsCustomViewModal from './components/SaveAsCustomViewModal/SaveAsCustomViewModal';
import Trends from './components/Trends/Trends';
import UserInfo from './components/UserInfo/UserInfo';
import WeightGraph from './components/WeightGraph/WeightGraph';

type UserUrlArgs = {
    shareLink?: string;
};

type UserArgs = {};

export default function User(_args: UserArgs) {
    const { shareLink } = useParams<UserUrlArgs>();

    const [confirmDeleteMeasurement, setConfirmDeleteMeasurement] =
        useState<PresentMeasurement | null>(null);

    const {
        data: userInfo,
        error: userInfoError,
        isFetching: isUserInfoLoading,
    } = useGetUserInfoQuery({
        shareLink: shareLink ? shareLink : null,
    });
    const [
        insertCustomView,
        {
            isLoading: isInsertingCustomView,
            error: insertingCustomViewError,
            reset: resetInsertCustomView,
        },
    ] = useInsertCustomViewMutation();
    const [
        updateCustomView,
        {
            isLoading: isUpdatingCustomView,
            error: updatingCustomViewError,
            reset: resetUpdateCustomView,
        },
    ] = useUpdateCustomViewMutation();
    const [
        deleteCustomView,
        {
            isLoading: isDeletingCustomView,
            error: deletingCustomViewError,
            reset: resetDeleteCustomView,
        },
    ] = useDeleteCustomViewMutation();
    const [
        setDefaultCustomView,
        {
            isLoading: isSettingDefaultCustomView,
            error: settingDefaultCustomViewError,
        },
    ] = useSetDefaultCustomViewMutation();

    const [
        insertMeasurement,
        { isLoading: isInsertingMeasurement, error: insertingMeasurementError },
    ] = useInsertMeasurementMutation();
    const [
        updateMeasurement,
        { isLoading: isUpdatingMeasurement, error: updatingError },
    ] = useUpdateMeasurementMutation();
    const [
        deleteMeasurement,
        {
            isLoading: isDeletingMeasurement,
            error: deletingMeasurementError,
            originalArgs: currentlyDeletingMeasurement,
        },
    ] = useDeleteMeasurementMutation();

    useEffect(() => {
        if (userInfo && (userInfo.name || userInfo.email)) {
            const name = userInfo.name || userInfo.email;
            setTitle(`${name}'s Measurements`);
        } else {
            setTitle('Measurements');
        }
    }, [userInfo]);

    const [measurementViewOptionId, setMeasurementViewOptionId] = useState(
        userInfo?.defaultMeasurementViewOption
    );
    if (userInfo && measurementViewOptionId === undefined) {
        setMeasurementViewOptionId(userInfo.defaultMeasurementViewOption);
    }

    const findMeasurementViewOption = useFindMeasurementViewOption(
        userInfo?.customMeasurementViewOptions ?? []
    );

    const measurementViewOption = useMemo(
        () =>
            measurementViewOptionId !== undefined
                ? findMeasurementViewOption(measurementViewOptionId)
                : undefined,
        [findMeasurementViewOption, measurementViewOptionId]
    );

    const [measurementViewValue, setMeasurementViewValue] = useState<
        MeasurementViewValue | undefined
    >(measurementViewOption);
    if (measurementViewOption && measurementViewValue === undefined) {
        setMeasurementViewValue(measurementViewOption);
    }

    const estimatedMeasurementCount = useMemo(() => {
        if (!measurementViewValue) {
            return 0;
        }
        // Consider being more accurate, somehow?
        switch (measurementViewValue.type) {
            case MeasurementViewType.Last30Days:
            case MeasurementViewType.ByMonth:
                return 30;

            case MeasurementViewType.ByYear:
                return 365;

            case MeasurementViewType.All:
            case MeasurementViewType.Range:
                return 100;
        }
    }, [measurementViewValue]);

    // TODO: Handle errors fetching measurements
    const { data: measurements, isFetching: measurementsAreFetching } =
        useGetMeasurementsQuery(
            !measurementViewValue
                ? skipToken
                : {
                      shareLink: shareLink ? shareLink : null,
                      type: measurementViewValue.type,
                      value: measurementViewValue.value,
                  }
        );

    const trendToDate = useMemo(
        () =>
            measurements && measurements.length ? measurements[0].date : null,
        [measurements]
    );
    const trendFromDate = useMemo(
        () =>
            measurements && measurements.length
                ? measurements[measurements.length - 1].date
                : null,
        [measurements]
    );
    const { data: trends, isFetching: trendsAreFetching } = useGetTrendsQuery(
        !trendFromDate || !trendToDate
            ? skipToken
            : {
                  shareLink: shareLink ? shareLink : null,
                  from: trendFromDate,
                  to: trendToDate,
              }
    );

    // TODO: Update after handling errors fetching measurements
    // const trendsOrNone = useMemo(() => {
    //     if (!measurementsAreFetching && !trendFromDate && !trendToDate) {
    //         return [];
    //     } else {
    //         return trends;
    //     }
    // },[measurementsAreFetching,trendFromDate,trendToDate,trends])

    const reversedMeasurements = useMemo(
        () => measurements?.slice().reverse() || [],
        [measurements]
    );

    const [confirmSaveAsCustomView, setConfirmSaveAsCustomView] =
        useState(false);

    const [nextMeasurementViewOptionId, setNextMeasurementViewOptionId] =
        useState<number | null>(null);
    useTransferValueEffect(
        nextMeasurementViewOptionId,
        () =>
            userInfo?.customMeasurementViewOptions.find(
                (v) => v.id === nextMeasurementViewOptionId
            ) !== undefined,
        () => {
            if (!nextMeasurementViewOptionId) {
                throw new Error('Unreachable');
            }
            setMeasurementViewOptionId(nextMeasurementViewOptionId);
        },
        () => {
            setNextMeasurementViewOptionId(null);
            setConfirmSaveAsCustomView(false);
        }
    );

    const [confirmDeleteCustomView, setConfirmDeleteCustomView] =
        useState(false);

    if (userInfoError) {
        if ('data' in userInfoError) {
            const response = JSON.parse(
                userInfoError.data as string
            ) as ApiResult;
            return (
                <div id="shareLink-error-dimmer">
                    <Dimmer active={true}>
                        <Header as="h2" icon inverted>
                            <Icon size="big" color="red" name="dont" />
                            {response.message}
                        </Header>
                    </Dimmer>
                </div>
            );
        } else {
            throw new Error('Unknown error: ' + JSON.stringify(userInfoError));
        }
    }

    if (
        !userInfo ||
        measurementViewOptionId === undefined ||
        measurementViewOption === undefined ||
        measurementViewValue === undefined
    ) {
        return null;
    }

    async function onInsertMeasurement(m: MeasurementEntry) {
        let weight = getWeightInKgFromMeasurementEntry(m);

        const measurement = {
            date: m.date,
            weight,
        };

        const result = await insertMeasurement(measurement);

        if ('error' in result) {
            return false;
        } else {
            return true;
        }
    }

    async function onUpdateMeasurement(m: MeasurementEntry) {
        let weight = getWeightInKgFromMeasurementEntry(m);

        const measurement = {
            date: m.date,
            weight,
        };

        const result = await updateMeasurement(measurement);

        if ('error' in result) {
            return false;
        } else {
            return true;
        }
    }

    async function onDeleteMeasurement(m: PresentMeasurement) {
        let result = await deleteMeasurement(m);
        if (!('error' in result)) {
            setConfirmDeleteMeasurement(null);
        }
    }

    async function onSaveCustomView(
        measurementViewValue: MeasurementViewValue,
        customViewData: string | { id: number; name: string }
    ) {
        if (typeof customViewData === 'string') {
            let result = await insertCustomView({
                name: customViewData,
                type: measurementViewValue.type,
                value: measurementViewValue.value,
            });
            if (!('error' in result)) {
                setNextMeasurementViewOptionId(result.data);
            }
        } else {
            let result = await updateCustomView({
                id: customViewData.id,
                name: customViewData.name,
                type: measurementViewValue.type,
                value: measurementViewValue.value,
            } as MeasurementViewOption);
            if (!('error' in result)) {
                setNextMeasurementViewOptionId(customViewData.id);
            }
        }
    }

    async function onDeleteCustomView(
        measurementViewOption: MeasurementViewOption
    ) {
        let result = await deleteCustomView(measurementViewOption.id);
        if (!('error' in result)) {
            setMeasurementViewOptionId(0);
            setMeasurementViewValue(findMeasurementViewOption(0));
            setConfirmDeleteCustomView(false);
        }
    }

    return (
        <Container text>
            <Header as="h2">{userInfo.displayName}'s Weight Monitor</Header>
            <Grid stackable columns={2} className="UserInfoStack">
                <Grid.Column>
                    <UserInfo {...userInfo} />
                </Grid.Column>
                <Grid.Column>
                    <Trends
                        energyMeasurement={
                            userInfo ? userInfo.energyMeasurement : null
                        }
                        weightMeasurement={
                            userInfo ? userInfo.weightMeasurement : null
                        }
                        trends={
                            (trendFromDate || trendToDate) && trends
                                ? trends
                                : null
                        }
                        isLoading={measurementsAreFetching || trendsAreFetching}
                    />
                </Grid.Column>
            </Grid>
            <MeasurementViewSelector
                customMeasurementViewOptions={
                    userInfo.customMeasurementViewOptions
                }
                years={userInfo.measurementYears}
                defaultMeasurementViewOptionId={
                    userInfo.defaultMeasurementViewOption
                }
                measurementViewOptionId={measurementViewOptionId}
                onMeasurementViewOptionIdChange={(newMeasurementViewOptionId) =>
                    setMeasurementViewOptionId(newMeasurementViewOptionId)
                }
                measurementViewValue={measurementViewValue}
                onMeasurementViewValueChange={(newValue) => {
                    if (newValue.manuallySelected) {
                        // If the selected value is the same as a custom view option's value,
                        // set the view option to that custom option.
                        const value = newValue.value;
                        let isCustom = false;
                        for (const customMeasurementViewOption of userInfo.customMeasurementViewOptions) {
                            if (
                                customMeasurementViewOption.type === value.type
                            ) {
                                if (
                                    isEqual(
                                        customMeasurementViewOption.value,
                                        value.value
                                    )
                                ) {
                                    setMeasurementViewOptionId(
                                        customMeasurementViewOption.id
                                    );
                                    isCustom = true;
                                    break;
                                }
                            }
                        }
                        if (!isCustom) {
                            switch (newValue.value.type) {
                                case MeasurementViewType.ByMonth:
                                    setMeasurementViewOptionId(BY_MONTH_ID);
                                    break;
                                case MeasurementViewType.ByYear:
                                    setMeasurementViewOptionId(BY_YEAR_ID);
                                    break;
                                case MeasurementViewType.Range:
                                    setMeasurementViewOptionId(RANGE_ID);
                                    break;
                            }
                        }
                    }
                    setMeasurementViewValue(newValue.value);
                }}
                onSaveAsCustomView={() => {
                    resetInsertCustomView();
                    resetUpdateCustomView();
                    setConfirmSaveAsCustomView(true);
                }}
                isSaving={
                    isInsertingCustomView ||
                    isUpdatingCustomView ||
                    isUserInfoLoading ||
                    nextMeasurementViewOptionId !== null
                }
                onDeleteCustomView={() => {
                    resetDeleteCustomView();
                    setConfirmDeleteCustomView(true);
                }}
                isDeleting={isDeletingCustomView}
                onSetAsDefaultView={() =>
                    setDefaultCustomView(measurementViewOptionId)
                }
                isSettingDefault={isSettingDefaultCustomView}
                settingDefaultError={settingDefaultCustomViewError ?? null}
            />
            <SaveAsCustomViewModal
                measurementViewValue={
                    confirmSaveAsCustomView
                        ? {
                              ...measurementViewValue,
                              name: measurementViewOption.name,
                          }
                        : null
                }
                customMeasurementViewOptions={
                    userInfo.customMeasurementViewOptions
                }
                isSaving={
                    isInsertingCustomView ||
                    isUpdatingCustomView ||
                    isUserInfoLoading ||
                    nextMeasurementViewOptionId !== null
                }
                savingError={
                    insertingCustomViewError ?? updatingCustomViewError ?? null
                }
                onCancel={() => setConfirmSaveAsCustomView(false)}
                onConfirm={(m) => onSaveCustomView(measurementViewValue, m)}
            />
            <ConfirmationModal
                onCancel={() => setConfirmDeleteCustomView(false)}
                open={confirmDeleteCustomView}
                isPerforming={isDeletingCustomView}
                header={`Deleting custom view '${measurementViewOption.name}'`}
                confirmButton={
                    <Button
                        content="Yes"
                        labelPosition="right"
                        icon={{
                            name: isDeletingCustomView ? 'spinner' : 'trash',
                            loading: isDeletingCustomView,
                        }}
                        negative
                        onClick={() =>
                            onDeleteCustomView(measurementViewOption)
                        }
                        disabled={isDeletingCustomView}
                    />
                }
                cancelText="No"
            >
                <Segment basic>
                    <ApiErrorMessage
                        error={deletingCustomViewError ?? null}
                        header="Error deleting custom view"
                    />
                    <p>Are you sure?</p>
                </Segment>
            </ConfirmationModal>
            <Segment attached="bottom" loading={measurementsAreFetching}>
                <WeightGraph
                    weightMeasurement={userInfo.weightMeasurement}
                    measurements={reversedMeasurements}
                />
            </Segment>
            <MeasurementsTable
                weightMeasurement={userInfo.weightMeasurement}
                measurements={measurements ?? []}
                estimatedRowCountAfterLoading={
                    measurementsAreFetching ? estimatedMeasurementCount : null
                }
                isInserting={isInsertingMeasurement}
                isUpdating={isUpdatingMeasurement}
                currentlyDeletingMeasurement={
                    confirmDeleteMeasurement ||
                    (isDeletingMeasurement && currentlyDeletingMeasurement) ||
                    null
                }
                deletingError={null}
                isReadOnly={shareLink ? true : false}
                onInsertMeasurement={onInsertMeasurement}
                insertingError={
                    insertingMeasurementError ? insertingMeasurementError : null
                }
                onEditMeasurement={onUpdateMeasurement}
                editingError={updatingError ? updatingError : null}
                onDeleteMeasurement={(m) => setConfirmDeleteMeasurement(m)}
            />
            <ConfirmationModal
                onCancel={() => setConfirmDeleteMeasurement(null)}
                open={confirmDeleteMeasurement !== null}
                isPerforming={isDeletingMeasurement}
                header={`Deleting measurement on ${
                    confirmDeleteMeasurement?.date
                        ? formatDate(new Date(confirmDeleteMeasurement?.date))
                        : ''
                }`}
                confirmButton={
                    <Button
                        content="Yes"
                        labelPosition="right"
                        icon={{
                            name: isDeletingMeasurement ? 'spinner' : 'trash',
                            loading: isDeletingMeasurement,
                        }}
                        negative
                        onClick={() => {
                            if (!confirmDeleteMeasurement) {
                                throw new Error('Unreachable');
                            }
                            onDeleteMeasurement(confirmDeleteMeasurement);
                        }}
                        disabled={isDeletingMeasurement}
                    />
                }
                cancelText="No"
            >
                <Segment basic>
                    <ApiErrorMessage
                        error={deletingMeasurementError ?? null}
                        header="Error deleting measurement"
                    />
                    <p>Are you sure?</p>
                </Segment>
            </ConfirmationModal>
        </Container>
    );
}
