import { useState } from 'react';
import {
    Button,
    Menu,
    MenuItemProps,
    Popup,
    SemanticShorthandCollection,
} from 'semantic-ui-react';

export type ActionPopupMenuArgs = {
    isBusy: boolean;
    menuItems: SemanticShorthandCollection<MenuItemProps>;
};

export default function ActionPopupMenu({
    isBusy,
    menuItems,
}: ActionPopupMenuArgs) {
    const [showingMenu, setShowingMenu] = useState(false);
    return (
        <Popup
            basic
            trigger={
                <Button
                    loading={isBusy}
                    disabled={isBusy}
                    icon="ellipsis vertical"
                    circular
                />
            }
            open={showingMenu}
            onOpen={() => setShowingMenu(true)}
            onClose={() => setShowingMenu(false)}
            content={
                <Menu
                    items={menuItems}
                    onItemClick={() => setShowingMenu(false)}
                    secondary
                    vertical
                />
            }
            on="click"
        />
    );
}
