import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useState } from 'react';
import { Button, Form, Menu, Popup, Table } from 'semantic-ui-react';

import ActionPopupMenu from 'client/components/widgets/ActionPopupMenu';
import ApiErrorMessage from 'client/components/widgets/ApiErrorMessage';
import MeasurementInput from 'client/components/widgets/MeasurementInput';
import { weight as renderWeight, weightValues } from 'client/conversions';
import { formatDate, isPromise } from 'client/helpers';
import { PresentMeasurement } from 'client/models/Measurement';
import { WeightMeasurement } from 'client/models/User';

import { MeasurementEntry } from '../../MeasurementsTable';
import './EditMeasurement.css';

export type EditMeasurementArgs = {
    weightMeasurement: WeightMeasurement;
    measurement: Readonly<PresentMeasurement>;
    isSaving: boolean;

    onEditMeasurement: (
        measurement: MeasurementEntry
    ) => boolean | Promise<boolean>;
    editingError: FetchBaseQueryError | SerializedError | null;

    onCancelEditMeasurement: () => void;
};

export default function EditMeasurement({
    weightMeasurement,
    measurement,
    isSaving,

    onEditMeasurement,
    editingError,
    onCancelEditMeasurement,
}: EditMeasurementArgs) {
    function createWeightMeasurementEntry(): MeasurementEntry {
        switch (weightMeasurement) {
            case WeightMeasurement.Kg:
            case WeightMeasurement.Lbs:
                return {
                    type: weightMeasurement,
                    date: measurement.date,
                    weight: weightValues(measurement.weight, weightMeasurement),
                };

            case WeightMeasurement.StLbs:
                const wv = weightValues(
                    measurement.weight,
                    weightMeasurement
                ).map((v) => v.toString());
                return {
                    type: WeightMeasurement.StLbs,
                    date: measurement.date,
                    weight: [wv[0], wv[1]],
                };
        }
    }

    const [editingMeasurement, setEditingMeasurement] =
        useState<MeasurementEntry>(createWeightMeasurementEntry());

    const [previousWeightMeasurement, setPreviousWeightMeasurement] =
        useState(weightMeasurement);
    if (weightMeasurement !== previousWeightMeasurement) {
        setEditingMeasurement(createWeightMeasurementEntry());
        setPreviousWeightMeasurement(weightMeasurement);
    }

    async function editMeasurement() {
        let result = onEditMeasurement(editingMeasurement);
        if (isPromise(result)) {
            result = await result;
        }

        if (result) {
            // Edit succeeded. Do something?
        }
    }

    const editingRows: JSX.Element[] = [];
    if (editingError) {
        editingRows.push(
            <Table.Row key="editingErrors">
                <Table.Cell colSpan="5">
                    <ApiErrorMessage
                        error={editingError}
                        header="Error editing measurement"
                    />
                </Table.Cell>
            </Table.Row>
        );
    }

    const hideLabels = editingMeasurement.type !== WeightMeasurement.StLbs;
    editingRows.push(
        <Table.Row key={measurement.date} className="EditMeasurementRow">
            <Table.Cell className="EditMeasurementCell-Date">
                {formatDate(measurement.date)}
            </Table.Cell>
            <Table.Cell
                className={
                    'EditMeasurementCell-Input ' +
                    (hideLabels ? 'hideLabel' : '')
                }
            >
                <Form onSubmit={() => editMeasurement()}>
                    <MeasurementInput
                        measurementValue={editingMeasurement}
                        onMeasurementValueChange={(v) =>
                            setEditingMeasurement({
                                ...v,
                                date: editingMeasurement.date,
                            })
                        }
                        disabled={isSaving}
                    />
                </Form>
            </Table.Cell>
            <Table.Cell className="EditMeasurementCell-Trend">
                {renderWeight(measurement.trend, weightMeasurement)}
            </Table.Cell>
            <Table.Cell className="EditMeasurementCell-Variance">
                {renderWeight(measurement.variance, weightMeasurement)}
            </Table.Cell>
            <Table.Cell className="EditMeasurementCell-Action actionCell">
                <div>
                    <ActionPopupMenu
                        isBusy={isSaving}
                        menuItems={[
                            {
                                key: 'save',
                                name: 'save',
                                content: 'Save Changes',
                                icon: 'save',
                                onClick: () => editMeasurement(),
                            },
                            {
                                key: 'cancel',
                                name: 'cancel',
                                content: 'Cancel Editing',
                                icon: 'cancel',
                                onClick: () => onCancelEditMeasurement(),
                            },
                        ]}
                    />
                </div>
            </Table.Cell>
        </Table.Row>
    );

    return <>{editingRows}</>;
}
