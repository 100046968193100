import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { invalidateAuthentication } from '../../redux/authenticationSlice';
import { AppDispatch } from '../../redux/store';

const mapDispatch = (dispatch: AppDispatch) => {
    return {
        logout: () => {
            dispatch(invalidateAuthentication());
        },
    };
};

const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type LogoutProps = PropsFromRedux & {};

class Logout extends Component<LogoutProps> {
    componentDidMount() {
        const { logout } = this.props;
        logout();
    }

    render() {
        return null;
    }
}

export default connector(Logout);
