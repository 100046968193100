export enum HttpResultType {
    ResponseData,
    JsonError,
    FetchError,
}

interface HttpResult {
    type: HttpResultType;
}

export interface ResponseData<D> extends HttpResult {
    type: HttpResultType.ResponseData;
    data: ApiResult<D>;
    ok: boolean;
    status: number;
    response: Response;
}

export function isResponseData<D>(type: any): type is ResponseData<D> {
    return (type as ResponseData<D>).type === HttpResultType.ResponseData;
}

export interface JsonError extends HttpResult {
    type: HttpResultType.JsonError;
    reason: SyntaxError;
    response: Response;
}

export const isJsonError = (type: any): type is JsonError => {
    return (type as JsonError).type === HttpResultType.JsonError;
};

export interface FetchError extends HttpResult, TypeError {
    type: HttpResultType.FetchError;
}

export const isFetchError = (type: any): type is FetchError => {
    return (type as FetchError).type === HttpResultType.FetchError;
};

export type ApiResult<D = null> = {
    success: boolean;
    errors: string[];
    message: string;
    data: D;
};

function handleAsJson<D>(
    response: Response
): Promise<ResponseData<D> | JsonError> {
    return response
        .json()
        .catch((reason): JsonError => {
            console.error(
                'HTTP response was not valid JSON, error:',
                reason,
                ', response object:',
                response
            );
            return {
                reason,
                response,
                type: HttpResultType.JsonError,
            };
        })
        .then((data: ApiResult<D> | JsonError) => {
            if (isJsonError(data)) {
                return data;
            } else {
                return {
                    data: data,
                    ok: response.ok,
                    status: response.status,
                    response,
                    type: HttpResultType.ResponseData,
                };
            }
        });
}

async function fetch<D>(url: RequestInfo, init?: RequestInit) {
    try {
        const response = await window.fetch(url, init);
        return await handleAsJson<D>(response);
    } catch (e: unknown) {
        let fetchError = e as FetchError;
        fetchError.type = HttpResultType.FetchError;
        return fetchError;
    }
}

export async function get<D>(url: RequestInfo) {
    return await fetch<D>(url, {
        credentials: 'include',
    });
}

export async function post<D>(url: RequestInfo, body?: any) {
    const options: RequestInit = {
        method: 'POST',
        credentials: 'include',
    };
    if (body) {
        options.body = JSON.stringify(body);
        options.headers = {
            'content-type': 'application/json',
        };
    }
    return await fetch<D>(url, options);
}

export async function deleet<D>(url: RequestInfo, body?: any) {
    const options: RequestInit = {
        method: 'DELETE',
        credentials: 'include',
    };
    if (body) {
        options.body = JSON.stringify(body);
        options.headers = {
            'content-type': 'application/json',
        };
    }
    return await fetch<D>(url, options);
}

export async function put<D>(url: RequestInfo, body: any) {
    return await fetch<D>(url, {
        body: JSON.stringify(body),
        headers: {
            'content-type': 'application/json',
        },
        method: 'PUT',
        credentials: 'include',
    });
}

export async function patch<D>(url: RequestInfo, body: any) {
    return await fetch<D>(url, {
        body: JSON.stringify(body),
        headers: {
            'content-type': 'application/json',
        },
        method: 'PATCH',
        credentials: 'include',
    });
}
