import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'whatwg-fetch';

import store from './client/redux/store';

import App from './client/app';
import './client/style.css';

async function main() {
    if (
        process.env.NODE_ENV === 'development' &&
        process.env.MOCK_API === 'true'
    ) {
        const { worker } = await import('./client/mocks/browser');
        worker.start();
    }

    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root')
    );
}

function retryUntilStylesheetsLoaded() {
    if (window.__WM_STYLESHEETS_LOADED.length === 2) {
        window.__WM_STILL_LOADING = false;
        window.__WM_LOADING_HANDOFF = main;
    } else {
        setTimeout(retryUntilStylesheetsLoaded, 250);
    }
}
retryUntilStylesheetsLoaded();
