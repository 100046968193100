import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDateAfterSeconds } from '../helpers';
import { isLoggedIn, login, logout, refresh } from '../services/authentication';
import {
    FetchError,
    isFetchError,
    isJsonError,
    JsonError,
} from '../services/http';
import { AppDispatch } from './store';
import { handlePayloadError } from './util';

export const invalidateAuthentication = createAsyncThunk<
    undefined,
    void,
    {
        rejectValue: string[] | JsonError | FetchError;
    }
>(
    'authentication/invalidateAuthentication',
    async (_type, { rejectWithValue }) => {
        // TODO: add try/catch?
        const logoutResponse = await logout();
        if (isJsonError(logoutResponse) || isFetchError(logoutResponse)) {
            return rejectWithValue(logoutResponse);
        } else {
            if (logoutResponse.ok && logoutResponse.data.success) {
                return;
            } else {
                return rejectWithValue(logoutResponse.data.errors);
            }
        }
    }
);

export const authenticate = createAsyncThunk<
    string,
    { username: string; password: string; rememberMe: boolean },
    {
        rejectValue: string[] | JsonError | FetchError;
    }
>(
    'authentication/authenticate',
    async ({ username, password, rememberMe }, { rejectWithValue }) => {
        // TODO: add try/catch?
        const loginResponse = await login(username, password, rememberMe);
        if (isJsonError(loginResponse) || isFetchError(loginResponse)) {
            return rejectWithValue(loginResponse);
        } else {
            if (loginResponse.ok && loginResponse.data.success) {
                return getDateAfterSeconds(
                    loginResponse.data.data.expiresIn
                ).toISOString();
            } else {
                return rejectWithValue(loginResponse.data.errors);
            }
        }
    }
);

export const validateAuthentication = createAsyncThunk<
    string,
    void,
    {
        rejectValue: string[] | JsonError | FetchError;
    }
>(
    'authentication/validateAuthentication',
    async (_type, { dispatch: dsptch, rejectWithValue }) => {
        const dispatch = dsptch as AppDispatch;

        // TODO: add try/catch?
        const isLoggedInResponse = await isLoggedIn();
        if (
            isJsonError(isLoggedInResponse) ||
            isFetchError(isLoggedInResponse)
        ) {
            return rejectWithValue(isLoggedInResponse);
        } else {
            if (isLoggedInResponse.ok && isLoggedInResponse.data.success) {
                return getDateAfterSeconds(
                    isLoggedInResponse.data.data.expiresIn
                ).toISOString();
            } else {
                dispatch(refreshAuthentication());
                return rejectWithValue(isLoggedInResponse.data.errors);
            }
        }
    }
);

export const refreshAuthentication = createAsyncThunk<
    string,
    void,
    {
        rejectValue: string[] | JsonError | FetchError;
    }
>(
    'authentication/refreshAuthentication',
    async (_type, { rejectWithValue }) => {
        // TODO: add try/catch?
        const refreshResponse = await refresh();
        if (isJsonError(refreshResponse) || isFetchError(refreshResponse)) {
            return rejectWithValue(refreshResponse);
        } else {
            if (refreshResponse.ok && refreshResponse.data.success) {
                return getDateAfterSeconds(
                    refreshResponse.data.data.expiresIn
                ).toISOString();
            } else {
                // TODO: More fine-grained error handling.
                return rejectWithValue(refreshResponse.data.errors);
            }
        }
    }
);

interface AuthenticationState {
    isAuthenticating: boolean;
    isValidating: boolean;
    isRefreshing: boolean;
    isLoggedIn: boolean | null;
    loginExpires: string | null;
    loginRedirectUrl: string | null;
    authenticationErrors: string[];
    validationErrors: string[];
}

const initialState: AuthenticationState = {
    isAuthenticating: false,
    isValidating: true,
    isRefreshing: false,
    isLoggedIn: null,
    loginExpires: null,
    loginRedirectUrl: null,
    authenticationErrors: [],
    validationErrors: [],
};

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setRedirectUrl: (state, action: PayloadAction<string>) => {
            state.loginRedirectUrl = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(invalidateAuthentication.pending, (state) => {
            state.isValidating = true;
            state.validationErrors = [];
        });
        builder.addCase(invalidateAuthentication.fulfilled, (state) => {
            state.isValidating = false;
            state.isLoggedIn = false;
            state.loginExpires = null;
        });
        builder.addCase(invalidateAuthentication.rejected, (state, action) => {
            state.isValidating = false;
            if (action.payload) {
                state.validationErrors = handlePayloadError(action.payload);
            }
        });

        builder.addCase(authenticate.pending, (state) => {
            state.isAuthenticating = true;
            state.authenticationErrors = [];
        });
        builder.addCase(authenticate.fulfilled, (state, action) => {
            state.loginExpires = action.payload;
            state.isLoggedIn = true;
            state.isAuthenticating = false;
            state.isValidating = false;
            state.authenticationErrors = [];
        });
        builder.addCase(authenticate.rejected, (state, action) => {
            state.isAuthenticating = false;
            if (action.payload) {
                state.authenticationErrors = handlePayloadError(action.payload);
            }
        });

        builder.addCase(validateAuthentication.pending, (state) => {
            state.isValidating = true;
            state.validationErrors = [];
        });
        builder.addCase(validateAuthentication.fulfilled, (state, action) => {
            state.loginExpires = action.payload;
            state.isLoggedIn = true;
            state.isValidating = false;
        });
        builder.addCase(validateAuthentication.rejected, (state, action) => {
            state.isValidating = false;
            if (action.payload) {
                state.validationErrors = handlePayloadError(action.payload);
            }
        });

        builder.addCase(refreshAuthentication.pending, (state) => {
            state.isRefreshing = true;
            state.authenticationErrors = [];
        });
        builder.addCase(refreshAuthentication.fulfilled, (state, action) => {
            state.loginExpires = action.payload;
            state.isLoggedIn = true;
            state.isRefreshing = false;
        });
        builder.addCase(refreshAuthentication.rejected, (state, action) => {
            state.isRefreshing = false;
            if (action.payload) {
                // TODO: refreshErrors?
                state.authenticationErrors = handlePayloadError(action.payload);
            }
        });
    },
});

export const { setRedirectUrl } = authenticationSlice.actions;

export default authenticationSlice.reducer;
