import { useMemo } from 'react';
import { Dropdown } from 'semantic-ui-react';

import { dateByNumbersFromJsDate } from 'client/models/DateByNumbers';
import { MeasurementViewOption, MeasurementViewType } from 'client/models/User';

export const LAST_30_DAYS_ID = 0;
export const BY_MONTH_ID = 1;
export const BY_YEAR_ID = 2;
export const RANGE_ID = 3;
export const ALL_ID = 4;
function getDefaultViewOptions(): MeasurementViewOption[] {
    const now = new Date();
    const { year, month, day } = dateByNumbersFromJsDate(now);
    return [
        {
            type: MeasurementViewType.Last30Days,
            name: 'Last 30 days',
            id: LAST_30_DAYS_ID,
        },
        {
            type: MeasurementViewType.ByMonth,
            name: 'By Month',
            id: BY_MONTH_ID,
            value: { year, month },
        },
        {
            type: MeasurementViewType.ByYear,
            name: 'By Year',
            id: BY_YEAR_ID,
            value: { year },
        },
        {
            type: MeasurementViewType.Range,
            name: 'Range',
            id: RANGE_ID,
            value: { start: { year, month, day }, end: { year, month, day } },
        },
        {
            type: MeasurementViewType.All,
            name: 'All',
            id: ALL_ID,
        },
    ];
}
export const defaultViewOptions = Object.freeze(getDefaultViewOptions());

export function useFindMeasurementViewOption(
    extraViewOptions: MeasurementViewOption[]
) {
    return useMemo(
        () => (id: number) =>
            extraViewOptions.find((o) => o.id === id) ||
            defaultViewOptions.find((o) => o.id === id),
        [extraViewOptions]
    );
}

export type MeasurementViewSelectorArgs = {
    disabled?: boolean;
    additionalMeasurementViewOptions: MeasurementViewOption[];
    selectedMeasurementViewOptionId: number;
    onSelectionChange: (
        newSelectedOptionId: number,
        selectedOption: MeasurementViewOption
    ) => void;
};

export default function MeasurementViewDropDown({
    disabled,
    additionalMeasurementViewOptions: additionalViewOptions,
    selectedMeasurementViewOptionId: selectedOptionId,
    onSelectionChange,
}: MeasurementViewSelectorArgs) {
    const allViewOptions = useMemo(
        () => [...defaultViewOptions, ...additionalViewOptions],
        [additionalViewOptions]
    );
    const dropdownOptions = useMemo(
        () =>
            allViewOptions.map((m) => ({
                key: m.id,
                value: m.id,
                text: m.name,
            })),
        [allViewOptions]
    );
    return (
        <Dropdown
            disabled={disabled}
            selection
            options={dropdownOptions}
            value={selectedOptionId}
            onChange={(_, { value }) => {
                onSelectionChange(
                    value as number,
                    allViewOptions.find(
                        (a) => a.id === (value as number)
                    ) as MeasurementViewOption
                );
            }}
        />
    );
}
