import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { User } from 'client/models/User';

import { changePassword } from '../services/authentication';
import {
    FetchError,
    JsonError,
    isFetchError,
    isJsonError,
} from '../services/http';
import { getUserInfo } from '../services/user';
import { RootState } from './store';
import { handlePayloadError } from './util';

export const fetchUserInfo = createAsyncThunk<
    User,
    void,
    {
        rejectValue: string[] | JsonError | FetchError;
    }
>('users/fetchUserInfo', async (_type, { rejectWithValue }) => {
    // TODO: add try/catch?
    const userInfoResponse = await getUserInfo();
    if (isJsonError(userInfoResponse) || isFetchError(userInfoResponse)) {
        return rejectWithValue(userInfoResponse);
    } else {
        if (userInfoResponse.ok && userInfoResponse.data.success) {
            return userInfoResponse.data.data;
        } else {
            return rejectWithValue(userInfoResponse.data.errors);
        }
    }
});

export type UpdatePasswordArgs = {
    currentPassword: string;
    newPassword: string;
};
export const updatePassword = createAsyncThunk<
    null,
    UpdatePasswordArgs,
    {
        rejectValue: string[] | JsonError | FetchError;
    }
>(
    'users/updatePassword',
    async ({ currentPassword, newPassword }, { rejectWithValue }) => {
        const changePasswordResponse = await changePassword(
            currentPassword,
            newPassword
        );
        if (
            isJsonError(changePasswordResponse) ||
            isFetchError(changePasswordResponse)
        ) {
            return rejectWithValue(changePasswordResponse);
        } else {
            if (
                changePasswordResponse.ok &&
                changePasswordResponse.data.success
            ) {
                return null;
            } else {
                return rejectWithValue(changePasswordResponse.data.errors);
            }
        }
    }
);

interface UserState {
    isFetching: boolean;
    userInfo: User | null;
    error: any;
    isUpdatingPassword: boolean;
    updatePasswordErrors: string[];
}

const initialState: UserState = {
    isFetching: false,
    userInfo: null,
    error: null,
    //
    isUpdatingPassword: false,
    updatePasswordErrors: [],
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUserInfo: (state) => {
            state.userInfo = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserInfo.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
            state.userInfo = action.payload;
            state.error = null;
            state.isFetching = false;
        });
        builder.addCase(fetchUserInfo.rejected, (state, action) => {
            state.error = action.payload;
            state.isFetching = false;
        });

        builder.addCase(updatePassword.pending, (state) => {
            state.isUpdatingPassword = true;
            state.updatePasswordErrors = [];
        });
        builder.addCase(updatePassword.fulfilled, (state) => {
            state.isUpdatingPassword = false;
            state.updatePasswordErrors = [];
        });
        builder.addCase(updatePassword.rejected, (state, action) => {
            state.isUpdatingPassword = false;
            if (action.payload) {
                state.updatePasswordErrors = handlePayloadError(action.payload);
            }
        });
    },
});

export const { clearUserInfo } = userSlice.actions;

export const selectUserInfo = (state: RootState) => state.user.userInfo;

export default userSlice.reducer;
