import { post } from './http';

export type ExpiresIn = {
    expiresIn: number;
};

export function login(email: string, password: string, rememberMe: boolean) {
    return post<ExpiresIn>('/api/auth/login', {
        userName: email,
        password: password,
        rememberMe: rememberMe,
    });
}

export function refresh() {
    return post<ExpiresIn>('/api/auth/refresh');
}

export function isLoggedIn() {
    return post<ExpiresIn>('/api/auth/isloggedin');
}

export function logout() {
    return post<null>('/api/auth/logout');
}

export function changePassword(currentPassword: string, newPassword: string) {
    return post<null>('/api/auth/changePassword', {
        currentPassword,
        newPassword,
    });
}
