import { uniqueId } from 'lodash';
import { useMemo } from 'react';
import { Form } from 'semantic-ui-react';

import {
    MeasurementEntry,
    MeasurementEntryWeight,
} from 'client/components/routes/User/components/MeasurementsTable/MeasurementsTable';
import { WeightMeasurement } from 'client/models/User';

export type MeasurementInputArgs = {
    measurementValue: MeasurementEntryWeight;
    onMeasurementValueChange: (newValue: MeasurementEntryWeight) => void;
    disabled: boolean;
};

export default function MeasurementInput({
    measurementValue,
    onMeasurementValueChange,
    disabled,
}: MeasurementInputArgs) {
    const measurementId = useMemo(() => uniqueId(`measurement`), []);
    const lbsId = useMemo(() => uniqueId(`lbs`), []);
    const stoneId = useMemo(() => uniqueId(`stone`), []);

    let inputs: JSX.Element[] = [];
    switch (measurementValue.type) {
        case WeightMeasurement.Kg:
        case WeightMeasurement.Lbs:
            const abbrTitle =
                measurementValue.type === WeightMeasurement.Kg
                    ? 'kilograms'
                    : 'pounds';
            inputs.push(
                <Form.Input
                    id={measurementId}
                    label={{
                        htmlFor: measurementId,
                        children: (
                            <abbr title={abbrTitle}>
                                {measurementValue.type.toLowerCase()}
                            </abbr>
                        ),
                    }}
                    type="number"
                    step="0.1"
                    key="editingMeasurement"
                    value={measurementValue.weight}
                    onChange={(_, { value }) =>
                        onMeasurementValueChange({
                            ...measurementValue,
                            weight: value,
                        })
                    }
                    disabled={disabled}
                    required
                />
            );
            break;
        case WeightMeasurement.StLbs:
            inputs.push(
                <Form.Input
                    key="editingMeasurementSt"
                    id={stoneId}
                    label={{
                        htmlFor: stoneId,
                        children: <abbr title="stone">st</abbr>,
                    }}
                    name="st"
                    type="number"
                    step="0.1"
                    value={measurementValue.weight[0]}
                    onChange={(_, { value }) =>
                        onMeasurementValueChange({
                            ...measurementValue,
                            weight: [value, measurementValue.weight[1]],
                        })
                    }
                    disabled={disabled}
                    required
                />
            );
            inputs.push(
                <Form.Input
                    key="editingMeasurementLbs"
                    id={lbsId}
                    label={{
                        htmlFor: lbsId,
                        children: <abbr title="pounds">lbs</abbr>,
                    }}
                    name="lbs"
                    type="number"
                    step="0.1"
                    value={measurementValue.weight[1]}
                    onChange={(_, { value }) =>
                        onMeasurementValueChange({
                            ...measurementValue,
                            weight: [measurementValue.weight[0], value],
                        })
                    }
                    disabled={disabled}
                    required
                />
            );
            break;
    }

    return <>{inputs}</>;
}
