import { useMemo } from 'react';
import { Form } from 'semantic-ui-react';

import {
    DateByNumbers,
    dateByNumbersAsIsoDateString,
    dateByNumbersFromJsDate,
} from 'client/models/DateByNumbers';

type DateSharedArgs = {
    name?: string;
    label: string;
    disabled?: boolean;

    onEnterPress?: () => void;
    onEscapePress?: () => void;
};

type DateOptionalArgs = DateSharedArgs & {
    required?: false;

    onDateChange: (newValue: DateByNumbers | null) => void;

    value: DateByNumbers | null;
};

type DateRequiredArgs = DateSharedArgs & {
    required: true;

    onDateChange: (newValue: DateByNumbers) => void;

    value: DateByNumbers;
};

export type DateSelectorArgs = DateOptionalArgs | DateRequiredArgs;

export default function DateSelector(args: DateSelectorArgs) {
    const name = args.name ? args.name : 'date';
    const required = args.required ? true : false;
    const value = args.value ? dateByNumbersAsIsoDateString(args.value) : '';

    const onKeyPressHandler = useMemo(() => {
        const onEnterPress = args.onEnterPress;
        const onEscapePress = args.onEscapePress;
        if (onEnterPress || onEscapePress) {
            return (e: React.KeyboardEvent) => {
                if (e.key === 'Enter' && onEnterPress) {
                    onEnterPress();
                } else if (e.key === 'Escape' && onEscapePress) {
                    onEscapePress();
                }
            };
        } else {
            return () => {};
        }
    }, [args.onEnterPress, args.onEscapePress]);

    return (
        <Form.Input
            label={args.label}
            name={name}
            inline
            type="date"
            value={value}
            onChange={(_, { value }) => {
                if (!args.required && !value) {
                    args.onDateChange(null);
                } else {
                    const date = dateByNumbersFromJsDate(
                        new Date(Date.parse(value))
                    );
                    args.onDateChange(date);
                }
            }}
            onKeyPress={onKeyPressHandler}
            required={required}
            disabled={args.disabled}
            autoComplete="off"
        />
    );
}
