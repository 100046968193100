import React, { useMemo } from 'react';
import { Line } from 'react-chartjs';

import LineToLinePlugin from 'client/chart-plugins/LineToLinePlugin';
import { rawWeight, weightValues } from 'client/conversions';
import { Measurement } from 'client/models/Measurement';
import { User, WeightMeasurement } from 'client/models/User';

const trendColor = '#2D882D';
const weightColor = '#AA3939';
const lineColor = '#882D60';

const trendLabel = 'Trend';
const weightLabel = 'Weight';

const lineToLinePlugin = new LineToLinePlugin(
    trendLabel,
    weightLabel,
    lineColor
);

let currentWeightMeasurement: WeightMeasurement;
function createMeasurementOptions(weightMeasurement: WeightMeasurement) {
    currentWeightMeasurement = weightMeasurement;
    return {
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 0,
                bottom: 0,
            },
        },

        tooltips: {
            mode: 'index',
            callbacks: {
                label: function (tooltip: any, tooltipArray: any) {
                    return rawWeight(tooltip.value, currentWeightMeasurement);
                },
            },
        },

        scales: {
            xAxes: [
                {
                    grid: {
                        drawTicks: true,
                    },
                    ticks: {
                        display: true,
                        callback: function (
                            tick: any,
                            index: any,
                            ticksArray: any
                        ) {
                            if (window.screen.width >= 768) {
                                return tick;
                            } else {
                                return ' ';
                            }
                        },
                    },
                },
            ],
            yAxes: [
                {
                    grid: {
                        drawTicks: true,
                    },
                    ticks: {
                        display: true,
                        callback: function (
                            tick: any,
                            index: any,
                            ticksArray: any
                        ) {
                            return rawWeight(tick, currentWeightMeasurement, 0);
                        },
                    },
                },
            ],
        },

        animation: false,
    };
}

export type WeightGraphProps = Pick<User, 'weightMeasurement'> & {
    measurements: Measurement[];
};

export default function WeightGraph({
    weightMeasurement,
    measurements,
}: WeightGraphProps) {
    const measurementOptions = useMemo(() => {
        return createMeasurementOptions(weightMeasurement);
    }, [weightMeasurement]);

    const measurementData = useMemo(() => {
        let fixedWeightMeasurement = weightMeasurement;
        if (fixedWeightMeasurement === WeightMeasurement.StLbs) {
            fixedWeightMeasurement = WeightMeasurement.Lbs;
        }

        return {
            labels: measurements
                .map((m) => new Date(m.date))
                .map((d) =>
                    d.toLocaleDateString(undefined, {
                        dateStyle: 'medium',
                    })
                ),
            datasets: [
                {
                    label: trendLabel,
                    data: measurements.map((m) =>
                        !m.present
                            ? null
                            : weightValues(m.trend, fixedWeightMeasurement)
                    ),
                    borderColor: trendColor,
                    fill: false,
                    spanGaps: true,
                },
                {
                    label: weightLabel,
                    data: measurements.map((m) =>
                        !m.present
                            ? null
                            : weightValues(m.weight, fixedWeightMeasurement)
                    ),
                    borderColor: weightColor,
                    lineTension: 0,
                    fill: false,
                    showLine: false,
                },
            ],
        };
    }, [weightMeasurement, measurements]);

    return (
        <Line
            data={measurementData}
            options={measurementOptions}
            plugins={[lineToLinePlugin]}
        />
    );
}
