import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppDispatch, RootState } from '../../redux/store';
import { toggleSidebar } from '../../redux/sidebarSlice';

import { Menu, Sidebar } from 'semantic-ui-react';

const mapState = (state: RootState) => {
    return {
        visible: state.sidebar.visible,
    };
};

const mapDispatch = (dispatch: AppDispatch) => {
    return {
        onClick: () => {
            dispatch(toggleSidebar());
        },
    };
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SidebarContainerProps = PropsFromRedux & {
    children?: React.ReactNode;
};

export const SidebarContainer = ({
    visible,
    onClick,
    children,
}: SidebarContainerProps) => {
    return (
        <Sidebar
            visible={visible}
            onClick={onClick}
            children={children}
            as={Menu}
            animation="push"
            direction="right"
            vertical
            inverted
            closable="true"
            className="tablet mobile only grid"
        />
    );
};

export default connector(SidebarContainer);
