function getFirstDatasetMeta(dataset) {
	const meta = dataset._meta;
	const keys = [...Object.keys(meta)];
	return meta[keys[0]];
}

function getDatasetByLabel(datasets, label) {
	for (const i in datasets) {
		const dataset = datasets[i];
		if (dataset.label === label) {
			return dataset;
		}
	}
	return null;
}

class LineToLinePlugin {
	constructor(fromDatasetLabel, toDatasetLabel, lineColor) {
		this.fromDatasetLabel = fromDatasetLabel;
		this.toDatasetLabel = toDatasetLabel;
		this.lineColor = lineColor;

		this.afterDatasetsDraw = this.afterDatasetsDraw.bind(this);
	}

	afterDatasetsDraw(chartInstance, easing) {
		const fromDataset = getDatasetByLabel(chartInstance.config.data.datasets, this.fromDatasetLabel);
		const toDataset = getDatasetByLabel(chartInstance.config.data.datasets, this.toDatasetLabel);

		if (!fromDataset || !toDataset) {
			return;
		}
		
		const fromDatasetMeta = getFirstDatasetMeta(fromDataset);
		const toDatasetMeta = getFirstDatasetMeta(toDataset);
		if (fromDatasetMeta.hidden === true || toDatasetMeta.hidden === true) {
			return;
		}

		const lineColor = this.lineColor;

		const ctx = chartInstance.ctx;
		ctx.save();
		ctx.lineWidth = 1;
		ctx.strokeStyle = lineColor;

		for (let i = 0; i < fromDatasetMeta.data.length; i++) {
			const fromDatasetViewData = fromDatasetMeta.data[i]._view;
			const toDatasetViewData = toDatasetMeta.data[i]._view;

			const x = fromDatasetViewData.x;
			const fromY = fromDatasetViewData.y;
			const toY = toDatasetViewData.y;

			ctx.beginPath();
			ctx.moveTo(x, fromY);
			ctx.lineTo(x, toY);
			ctx.stroke();
		}

		ctx.restore();
	}
};

export default LineToLinePlugin;
