import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import {
    Message,
    MessageHeaderProps,
    SemanticShorthandItem,
} from 'semantic-ui-react';

import { useErrors } from 'client/hooks';

export type ApiErrorMessageArgs = {
    error: FetchBaseQueryError | SerializedError | null;
    header: SemanticShorthandItem<MessageHeaderProps>;
};

export default function ApiErrorMessage({
    error,
    header,
}: ApiErrorMessageArgs) {
    const errors = useErrors(error);
    const errorElements = useMemo(
        () => errors.map((e) => <li key={e}>{e}</li>),
        [errors]
    );

    return errorElements.length ? (
        <Message error header={header} content={<ul>{errorElements}</ul>} />
    ) : null;
}
