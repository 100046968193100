import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import { Container, Divider, Sidebar } from 'semantic-ui-react';

import './app.css';
import AppStateHandler from './components/functional/AppStateHandler';
import AccountSecurity from './components/routes/AccountSecurity';
//import ConfirmEmailNagger from './components/ConfirmEmailNagger';
import { RequireAuth, RequireNotAuth } from './components/routes/AuthHOC';
import Home from './components/routes/Home';
import Login from './components/routes/Login';
import Logout from './components/routes/Logout';
import NotFound from './components/routes/NotFound';
import Settings from './components/routes/Settings';
import Sharing from './components/routes/Sharing';
import User from './components/routes/User/User';
import Menu from './components/widgets/Menu';
import MenuList from './components/widgets/MenuList';
import SidebarContainer from './components/widgets/SidebarContainer';
import MenuModel, { MenuItem } from './models/Menu';

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: new MenuModel(),
        };
    }

    componentDidMount() {
        const menu = new MenuModel([
            new MenuItem('/user', 'Measurements', true, null, true),
            new MenuItem('/share', 'Sharing', true, null, true),
            new MenuItem('/settings', 'Settings', true, null, true),
            new MenuItem('/account', 'Account security', true, null, true),
        ]);
        menu.addNamedMenuitem(
            'home',
            new MenuItem('/', 'WeightMonitor.org', null, null, true)
        );
        menu.addNamedMenuitem('login', new MenuItem('/login', 'Sign in'));
        menu.addNamedMenuitem('logout', new MenuItem('/logout', 'Sign out'));
        this.setState({ menu });
    }

    render() {
        const { menu } = this.state;

        const RoutedMenuList = withRouter(MenuList);

        const LoginRNA = RequireNotAuth(Login, '/user');
        const LoginComponent = () => <LoginRNA redirectUrl="/user" />;

        return (
            <BrowserRouter>
                <Sidebar.Pushable>
                    <SidebarContainer>
                        <RoutedMenuList menuItems={menu && menu.menuItems} />
                    </SidebarContainer>
                    <Sidebar.Pusher>
                        <AppStateHandler />

                        <Menu menu={menu} />
                        <Divider hidden />
                        <Container>
                            {/* <ConfirmEmailNagger /> */}
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    component={RequireNotAuth(Home, '/user')}
                                />
                                <Route
                                    exact
                                    path="/login"
                                    component={LoginComponent}
                                />
                                <Route
                                    exact
                                    path="/logout"
                                    component={RequireAuth(Logout)}
                                />
                                <Route
                                    exact
                                    path="/user/:shareLink"
                                    component={RequireAuth(User)}
                                />
                                <Route
                                    exact
                                    path="/user"
                                    component={RequireAuth(User)}
                                />
                                <Route
                                    exact
                                    path="/share"
                                    component={RequireAuth(Sharing)}
                                />
                                <Route
                                    exact
                                    path="/settings"
                                    component={RequireAuth(Settings)}
                                />
                                <Route
                                    exact
                                    path="/account"
                                    component={RequireAuth(AccountSecurity)}
                                />
                                <Route component={NotFound} />
                            </Switch>
                        </Container>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </BrowserRouter>
        );
    }
}
