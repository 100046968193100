import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React, { useMemo } from 'react';
import { Button, Header, Segment } from 'semantic-ui-react';

import {
    MeasurementViewOption,
    MeasurementViewType,
    MeasurementViewValue,
} from 'client/models/User';

import ApiErrorMessage from './ApiErrorMessage';
import DateSelector from './DateSelector';
import MeasurementViewDropDown, {
    useFindMeasurementViewOption,
} from './MeasurementViewDropDown';
import './MeasurementViewSelector.css';
import MonthSelector from './MonthSelector';
import YearSelector from './YearSelector';

type MeasurementViewSelectorArgs = {
    customMeasurementViewOptions: MeasurementViewOption[];
    years: number[];
    defaultMeasurementViewOptionId: number;

    onMeasurementViewOptionIdChange: (
        newSelectedMeasurementViewOptionId: number
    ) => void;
    measurementViewOptionId: number;

    onMeasurementViewValueChange: (newValue: {
        manuallySelected: boolean;
        value: MeasurementViewValue;
    }) => void;
    measurementViewValue: MeasurementViewValue;

    onSaveAsCustomView: () => void;
    isSaving: boolean;

    onDeleteCustomView: () => void;
    isDeleting: boolean;

    onSetAsDefaultView: () => void;
    isSettingDefault: boolean;
    settingDefaultError: FetchBaseQueryError | SerializedError | null;
};

export default function MeasurementViewSelector({
    customMeasurementViewOptions,
    years,
    defaultMeasurementViewOptionId,

    onMeasurementViewOptionIdChange,
    measurementViewOptionId,

    onMeasurementViewValueChange,
    measurementViewValue,

    onSaveAsCustomView,
    isSaving,

    onDeleteCustomView,
    isDeleting,

    onSetAsDefaultView,
    isSettingDefault,
    settingDefaultError,
}: MeasurementViewSelectorArgs) {
    const findMeasurementViewOption = useFindMeasurementViewOption(
        customMeasurementViewOptions
    );

    const measurementViewOption = useMemo(
        () => findMeasurementViewOption(measurementViewOptionId),
        [findMeasurementViewOption, measurementViewOptionId]
    );

    if (!measurementViewOption) {
        throw new Error(
            '`measurementViewOption` was not present among the available measurement view options'
        );
    }

    const selectorSegments = useMemo(() => {
        const segments = [];
        switch (measurementViewValue.type) {
            case MeasurementViewType.ByMonth:
                segments.push(
                    <Segment attached key="bymonth">
                        <MonthSelector
                            label="Month: "
                            value={measurementViewValue.value}
                            onMonthChange={(newValue) => {
                                onMeasurementViewValueChange({
                                    manuallySelected: true,
                                    value: {
                                        type: MeasurementViewType.ByMonth,
                                        value: newValue,
                                    },
                                });
                            }}
                            required
                        />
                    </Segment>
                );
                break;

            case MeasurementViewType.ByYear:
                segments.push(
                    <Segment attached key="byyear">
                        <YearSelector
                            label="Year: "
                            value={measurementViewValue.value.year}
                            onYearChange={(newValue) => {
                                onMeasurementViewValueChange({
                                    manuallySelected: true,
                                    value: {
                                        type: MeasurementViewType.ByYear,
                                        value: { year: newValue },
                                    },
                                });
                            }}
                            years={years}
                        />
                    </Segment>
                );
                break;

            case MeasurementViewType.Range:
                segments.push(
                    <Segment
                        attached
                        key="range-start"
                        className="RangeSegment"
                    >
                        <DateSelector
                            label="Start: "
                            value={measurementViewValue.value.start}
                            onDateChange={(start) => {
                                onMeasurementViewValueChange({
                                    manuallySelected: true,
                                    value: {
                                        type: MeasurementViewType.Range,
                                        value: {
                                            start,
                                            end: measurementViewValue.value.end,
                                        },
                                    },
                                });
                            }}
                        />
                    </Segment>
                );
                segments.push(
                    <Segment attached key="range-end" className="RangeSegment">
                        <DateSelector
                            label="End: "
                            value={measurementViewValue.value.end}
                            onDateChange={(end) => {
                                onMeasurementViewValueChange({
                                    manuallySelected: true,
                                    value: {
                                        type: MeasurementViewType.Range,
                                        value: {
                                            end,
                                            start: measurementViewValue.value
                                                .start,
                                        },
                                    },
                                });
                            }}
                        />
                    </Segment>
                );
                break;

            case MeasurementViewType.Last30Days:
            case MeasurementViewType.All:
                break;
        }
        return segments;
    }, [measurementViewValue, onMeasurementViewValueChange, years]);

    const isBusy = isSaving || isDeleting || isSettingDefault;
    const isDefaultView =
        measurementViewOptionId === defaultMeasurementViewOptionId;
    const buttonIcon =
        settingDefaultError !== null
            ? 'times circle outline'
            : isSettingDefault
            ? 'spinner'
            : isDefaultView
            ? 'check'
            : 'thumbtack';
    const buttonColor = settingDefaultError
        ? 'red'
        : isDefaultView
        ? 'green'
        : undefined;

    return (
        <>
            <Header as="h3" attached="top">
                Progress
            </Header>
            {settingDefaultError ? (
                <Segment attached>
                    <ApiErrorMessage
                        error={settingDefaultError}
                        header="Could not set as default view"
                    />
                </Segment>
            ) : null}
            <Segment attached className="measurementViewDropDownSegment">
                <div className="measurementViewDropDownSegment-Show">
                    Show:{' '}
                </div>
                <div className="measurementViewDropDownSegment-DropDown">
                    <MeasurementViewDropDown
                        disabled={isBusy}
                        additionalMeasurementViewOptions={
                            customMeasurementViewOptions
                        }
                        selectedMeasurementViewOptionId={
                            measurementViewOptionId
                        }
                        onSelectionChange={(
                            newSelectedMeasurementViewOptionId,
                            newSelectedMeasurementViewOption
                        ) => {
                            onMeasurementViewOptionIdChange(
                                newSelectedMeasurementViewOptionId
                            );
                            onMeasurementViewValueChange({
                                manuallySelected: false,
                                value: newSelectedMeasurementViewOption,
                            });
                        }}
                    />
                </div>{' '}
                <Button
                    className="measurementViewDropDownSegment-SetAsDefaultView"
                    content={
                        isDefaultView
                            ? 'Is Default View'
                            : 'Set as Default View'
                    }
                    onClick={() => onSetAsDefaultView()}
                    disabled={isDefaultView || isBusy}
                    labelPosition="right"
                    icon={{
                        name: buttonIcon,
                        loading: isSettingDefault,
                        color: buttonColor,
                    }}
                ></Button>
                {measurementViewOptionId < 10 ? (
                    <Button
                        className="measurementViewDropDownSegment-SaveDefaultView"
                        content="Save As Custom View"
                        onClick={() => onSaveAsCustomView()}
                        disabled={isBusy}
                        labelPosition="right"
                        icon={{
                            name: isSaving ? 'spinner' : 'save',
                            loading: isSaving,
                        }}
                    />
                ) : (
                    <Button
                        className="measurementViewDropDownSegment-SaveDefaultView"
                        content="Delete Custom View"
                        onClick={() => onDeleteCustomView()}
                        disabled={isBusy}
                        labelPosition="right"
                        icon={{
                            name: isDeleting ? 'spinner' : 'trash',
                            loading: isDeleting,
                        }}
                    ></Button>
                )}
            </Segment>
            {selectorSegments}
        </>
    );
}
